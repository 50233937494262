function DateRangePicker() {
    return {
        initComponent: function (selector) {
            var conf = selector.data();
            selector.find("[daterangepicker-event]").daterangepicker({
                "startDate": conf.dateStart,
                "endDate": conf.dateEnd,
                "opens": "left"
            }, function(start, end, label) {
                console.log("New date range selected: ' + start.format('YYYY-MM-DD') + ' to ' + end.format('YYYY-MM-DD') + ' (predefined range: ' + label + ')");
            });
            selector.find("[daterangepicker-event]").on('apply.daterangepicker', function(ev, picker) {
                selector.find("[daterangepicker-start]").val(picker.startDate.format('MM/DD/YYYY'));
                selector.find("[daterangepicker-end]").val(picker.endDate.format('MM/DD/YYYY'));
                selector.submit();
            });
        },
        initAll: function () {
            var _this = this;
            $(document).find('*[daterangepicker-init]').once("data_daterangepicker").each(function (key, value) {
                _this.initComponent($(this));
            });
        }
    }
}


$(document).ready( function(){
    var text = DateRangePicker();
    text.initAll();
});