/**
 * Search Auto complete: Auto suggest search start
 */
RetailShop.searchAutoSuggestion = function(){
    // Auto suggest search start on press key in search box field
    $('input.searchTerm').on('keyup', function(e){
        if($(this).val().length >= 3){
            // Select suggested search result items by Keyboard keys
            if(e.keyCode == 40 || e.keyCode == 38){
                var suggestionList = $(this).siblings('ul.search-suggest-results').children('li');
                // Move focus on suggested search result list by press down arrow key
                if(e.keyCode == 40){
                    var noSuggestionSeleted=true;
                    // If last list item selected then nothing happen on press down arrow key
                    if(suggestionList.last().hasClass('active-suggestion')){
                        return false;
                    }
                    if(suggestionList.length > 0 && noSuggestionSeleted == true){
                        $.each(suggestionList, function(idx, value){
                            if($(this).hasClass('active-suggestion') && (idx < suggestionList.length-1)){
                                $(this).removeClass('active-suggestion');
                                // Select next list item from auto suggested search list
                                $(this).next().addClass('active-suggestion');
                                noSuggestionSeleted=false;
                                return false;
                            }
                        });
                        // Select first auto suggested list item
                        if(noSuggestionSeleted == true){
                            suggestionList.first().addClass('active-suggestion');
                        }
                    }
                }
                // Move focus on suggested search result list by press up arrow key
                else if(e.keyCode == 38){
                    // If First list item selected then nothing happen on press up arrow key
                    if(suggestionList.first().hasClass('active-suggestion')){
                        return false;
                    }
                    if(suggestionList.length > 0){
                        $.each(suggestionList, function(idx, value){
                            if($(this).hasClass('active-suggestion')){
                                $(this).removeClass('active-suggestion');
                                // Select previous list item from auto suggested search list
                                $(this).prev().addClass('active-suggestion');
                                return false;
                            }
                        });
                    }
                }

            } else {
                // Start AJAX from auto suggestion search when user stop typing
                if (sessionTimedOut) {
                    clearTimeout(sessionTimedOut);
                }
                sessionTimedOut = setTimeout(autoSuggestSearch($(this), 500));
            }
        }

    });

    // Auto suggest search item select by enter or tab key
    $('input.searchTerm').on('keydown', function(e){
        var inputElement= $(this);
        var formElement = $(this).parents('form[role="search"]');
        var suggestionList = $(this).siblings('ul.search-suggest-results').children('li');
        // Move selected suggested list item into search field by tab key
        if(e.keyCode == 9){
            e.preventDefault();
            if($(suggestionList).length > 0){
                $(suggestionList).each( function(){
                    if($(this).hasClass('active-suggestion')){
                        inputElement.val($(this).find('.search-result').attr('data-search-result'));
                        inputElement.siblings('ul.search-suggest-results').empty().hide();
                        return false;
                    }
                });
            }
        }

        // Move selected suggested list item into search field and submit search form by enter key
        else if(e.keyCode == 13){
            e.preventDefault();
            // there are suggested values
            if($(suggestionList).length > 0){
                $(suggestionList).each( function(index){
                    //if something selected in auto suggestion list submit form with selected value
                    if($(this).hasClass('active-suggestion')){
                        $(this).find('.search-result')[0].click();
                        return false;
                    }
                    //if nothing selected in auto suggestion list submit form with entered value
                    else {
                        if(index == ($(suggestionList).length-1) ) {
                            inputElement.siblings('ul.search-suggest-results').empty().hide();
                            formElement.submit();
                        };
                    }
                });
                //there are not suggested values
            } else {
                if(inputElement.val().length > 0)
                {
                    // Submit search form
                    $(this).parents('form[role="search"]').submit();
                }
            }
        }
    });

    $('form[role="search"]').on('reset', function () {
        var inputElement = $(this).find('input.searchTerm');
        inputElement[0].defaultValue = '';
        inputElement[0].value = '';
    })
}

/**
 * Start auto suggest search  when user stop typing
 */
function autoSuggestSearch (searchbox){
    showSuggestSearchList(searchbox);
}

/**
 * Display auto suggestion search list
 */
function showSuggestSearchList(searchInput){
    var searchTerm = $.trim($(searchInput).val());
    var ajaxurl = $(searchInput).attr('data-suggesturl') + "&SearchTerm=" + searchTerm;
    $.ajax({
        url: ajaxurl,
        type: 'post',
        datatype: 'text/html',
        success: function(data){
            // Show auto suggestion list item block
            if ($(data).filter('.dt-c-SearchPanel-results-part').length > 0){
                $('[data-search="results"]').html($(data)).fadeIn(200);
            } else {
                // Hide Auto suggestion block when suggestion result are zero
                $('[data-search="results"]').empty();
            }
        }
    });
}

/**
 * Click on auto suggest search list item
 */
$(document).on("click", "ul.search-suggest-results li button", function(){
    var formElement = $(this).parents('form[role="search"]');
    formElement.children('input.searchTerm').val($(this).attr('data-search-result'));
    formElement.children('.search-suggest-results').empty().hide();
    // Submit Search form
    formElement.submit();
});