var ItemWPrice = (function(window) {

    function ItemWPrice(id, obj) {
        this.id = id;
        this.obj = obj;
        this.SELECTORS = {
            item : "*[data-tablevariationwprice-item-id='"+id+"']",
            qty : "*[data-tablevariationwprice-item-qty='"+id+"']",
            price : "*[data-tablevariationwprice-item-unitprice='"+id+"']"
        };
        this.controller("item.add");
    }

    ItemWPrice.prototype.setValueQty = function(qty) {
        this.obj.qty = qty;
    };
    ItemWPrice.prototype.setValuePrice = function(price) {
        this.obj.price = price;
    };
    ItemWPrice.prototype.event = function(url) {
        var $item = this;
        $(this.SELECTORS.qty).once("qty").on( "spinstop", function( event, ui ) {
            $item.controller("item.qty.change",$(this).val());
            $item.controller("item.price.change");
        });
    };
    ItemWPrice.prototype.controller = function(dispatch,data) {
        var $item = this;
        switch (dispatch) {
            case "item.add":
                $("*[data-tablevariationwprice-items]").append($item.getTemplate("HBS_TableVariationWithoutPriceItem",$item));
                uiSpinner.init();
                $item.event();
                $item.controller("item.price.change");
                break;
            case "item.price.change":
                var unitPrice=$item.obj.unitprice.replace(/\s+/g,'').replace(",",".");                    
                var price = parseFloat($item.obj.qty)*parseFloat(unitPrice);
                $item.setValuePrice(price.toFixed(2));
                $($item.SELECTORS.price).html(price.toFixed(2).replace(".",","));
                break;
            case "item.qty.change":
                $item.setValueQty(data);
                break;
            default:
                break;
        }
        $('*[data-tablevariationwprice="true"]').trigger("tablevariationwprice:updateview");
    };
    ItemWPrice.prototype.getTemplate = function(template,data){
        var _template = handlebarsDom.compileFromDomID(template);
        return _template(data);
    };
    return ItemWPrice;
})(window);

var TableVariationWPrice = (function() {

    var _config = {
        nbItem : 0,
        isLoading : false
    };
    var _map = {};

    function getMapPrice(tva){
        var price = 0;
        $.each(_map, function(i, e) {
            price = parseFloat(price) + parseFloat(e.item.obj[tva].replace(",","."));
            var qty = parseFloat(e.item.obj['qty']);
            var basequantity = parseFloat(e.item.obj['basequantity'].replace(/\s+/g,'').replace(",","."));
            var baseunit = e.item.obj['baseunit'];
            if(qty>0 && basequantity >0 && null != baseunit && '' != baseunit){
                $("*[data-tablevariationwprice-totalquantity-"+i+"]").html("Soit " + qty*basequantity + baseunit);
            }
            else{
                $("*[data-tablevariationwprice-totalquantity-"+i+"]").html("");
            }
        });
        return price.toFixed(2).replace(".",",");
    }
    function setNbItem(value){_config.nbItem = value;}
    function getNbItem(){return _config.nbItem;}

    function isItemAtleastoneChecked(){
        var checked = false;
        //var count = Object.keys(_map).length;
        $.each(_map, function(i, e) {
            if (e.item.obj.qty > 0) {
                checked = true;
            }
        });
        return checked
    }

    function updateView(){
        $("*[data-tablevariationwprice-addtocard]").prop("disabled",!isItemAtleastoneChecked());

    }

    function build(data){
        $.each(data.results, function(i, obj) {
            setNbItem(getNbItem() + 1);
            _map[getNbItem()] = {
                item: new ItemWPrice(getNbItem(), obj)
            };
        });
        TableVariationWPrice.updateView();
    }

    function init(){
        if($('*[data-tablevariationwprice="true"]').length){
            _config = {
                nbItem : 0
            };
            TableVariationWPrice.build(window.TableVariationWithoutPricesJSON);
            $('*[data-tablevariationwprice="true"]').bind("tablevariationwprice:updateview",function(event, token){
                TableVariationWPrice.updateView();
            });
        }
    }

    // Expose methods.
    return {
        config:_config,
        init: init,
        build: build,
        setNbItem:setNbItem,
        getNbItem:getNbItem,
        isItemAtleastoneChecked:isItemAtleastoneChecked,
        updateView:updateView
    };

})();

$(document).ready( function(){
    TableVariationWPrice.init();
});
