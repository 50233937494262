/**
 * @namespace
 */

var handlebarsDom = {};
(function() {
	var cache = [];

	function compileFromDomID(domid) {
		var existing = cache[domid];
		if (existing === undefined) {
			cache[domid] = Handlebars.compile($("#" + domid).html());
		}

		return cache[domid];
	}

	handlebarsDom.compileFromDomID=compileFromDomID
})();