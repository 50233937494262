function dtHeader() {
    return {
        setup: function () {
            this.DOM = {
                navigation: qs('[data-header="navigation"]', this.element),
                burgers: qsa('[data-header="burger-toggler"]', this.element),
                layerClose: null, // affected when layer is instanciated on small screen
            };

            this.state = {
                display: '',
                scrolled: false,
                burgerSetup: false,
            };

            this.layer = dtComponents.getComponent(document.body, 'dt-layer');

            this.checkDisplay = this.checkDisplay.bind(this);
            this.toggleBurger = this.toggleBurger.bind(this);

            this.checkDisplay();
            this.initEvents();
        },

        cleanup: function () {
            this.cleanupEvents();
        },

        initEvents: function () {
            document.addEventListener('dtviewscroll', this.checkDisplay);
            document.addEventListener('dtviewresize', this.checkDisplay);
        },

        cleanupEvents: function () {
            document.removeEventListener('dtviewscroll', this.checkDisplay);
            document.removeEventListener('dtviewresize', this.checkDisplay);
        },

        checkDisplay: function () {
            this.updateStickyState();

            var display = getDisplay(this.element);

            if (this.state.display !== display) {
                this.state.display = display;

                switch (getDisplay(this.element)) {
                    case 'small':
                        this.setupBurger();
                        break;

                    case 'large':
                        this.cleanupBurger();
                        break;
                }
            }
        },

        updateStickyState: function () {
            var scrolled = (window.scrollY > 0);

            if (this.state.scrolled !== scrolled) {
                this.state.scrolled = scrolled;
                this.element.setAttribute('data-sticky', scrolled);
            }
        },

        setupBurger: function () {
            if (!!this.state.burgerSetup)
                return;

            this.DOM.burgers.forEach(function (burger) {
                burger.addEventListener('click', this.toggleBurger);
            }, this);

            this.state.burgerSetup = true;
        },

        cleanupBurger: function () {
            if (!this.state.burgerSetup)
                return;

            this.layer.cleanup();

            this.DOM.navigation.removeAttribute('data-shown');

            this.DOM.burgers.forEach(function (burger) {
                burger.removeEventListener('click', this.toggleBurger);
            }, this);

            this.state.burgerSetup = false;
        },

        toggleBurger: function () {    
            this.layer.cleanup();
            if (!this.layer.state.toggled) {
                this.layer.show(this.DOM.navigation, 'burger');

                if (!this.state.listenLayerClose) {
                    this.state.listenLayerClose = true;
                }

                this.DOM.navigation.setAttribute('data-shown', 'true');
            } else {
                this.layer.hide();
            }
        }
    }
}