var Gallery = {};
(function() {


    Gallery.events = function() {
        $('*[data-galleryzoom-shadow],*[data-galleryzoom-close]').once("galleryzoomshadow").on('click',function(e) {
            e.preventDefault();
            $("*[data-galleryzoom-modal]").removeClass("isActive");

        });
        $('*[data-gallery-top] img').once("galleryitem").on('click',function(e) {
            e.preventDefault();
            var index = $(this).parent().index();
            $("*[data-galleryzoom-top]").remove();
            $("*[data-galleryzoom-modal]").addClass("isActive");
            var HBS_GalleryZoom = handlebarsDom.compileFromDomID("HBS_GalleryZoom");
            $("*[data-galleryzoom-wrapper]").append(HBS_GalleryZoom({"index":index}));
            Gallery.build("galleryzoom",false);
            if(bpoint.isBreakpointMDup()){
                $('*[data-galleryzoom-zoom]').zoom({
                    on:'click',
                    onZoomIn: function(){
                        $(this).addClass("zoomIn");
                    },
                    onZoomOut: function(){
                        $(this).removeClass("zoomIn");
                    }
                });
            }
        });
    }

    Gallery.build = function(name,thumbs) {
        var galleryTopOptions = {};
        if(thumbs){
            var  galleryThumbsOptions = {
                spaceBetween: 10,
                slidesPerView: 4,
                freeMode: true,
                watchSlidesVisibility: true,
                watchSlidesProgress: true,
            }
            var galleryThumbs = new Swiper('*[data-'+name+'-thumbs]', galleryThumbsOptions);
            galleryTopOptions["thumbs"] = {
                swiper: galleryThumbs
            }
        }
        galleryTopOptions["navigation"] = {
            nextEl: $('*[data-'+name+'-top]').find('.swiper-button-next'),
            prevEl: $('*[data-'+name+'-top]').find('.swiper-button-prev')
        }
        galleryTopOptions["pagination"] = {
            el: $('*[data-'+name+'-top]').find('.swiper-pagination')
        }
        galleryTopOptions["initialSlide"] = ($('*[data-'+name+'-top]').data('swiper-initialslide') != undefined) ? $('*[data-'+name+'-top]').data('swiper-initialslide') : 0;
        new Swiper('*[data-'+name+'-top]', galleryTopOptions);
    }

    Gallery.init = function() {

        var divWrapper = $('<div class="galleryzoom-wrapper" data-galleryzoom-wrapper></div>');
        var divClose = $('<div class="galleryzoom-close" data-galleryzoom-close><span class="icon icon-close"></span></div>');
        var divShadow = $('<div class="galleryzoom-shadow" data-galleryzoom-shadow></div>');
        var divModal = $('<div class="galleryzoom-modal" data-galleryzoom-modal></div>');
        divWrapper.append(divClose);
        divModal.append(divShadow);
        divModal.append(divWrapper);
        $('body').append(divModal);

        Gallery.build("gallery",true);
        Gallery.events();
    }

})();

$(document).ready( function(){
    Gallery.init();
});
