/**
 * @namespace
 */

var modalExtend = {};
(function() {

    /**
     * @memberOf requestHandlebars
     */
    function  requestHandlebars(elt) {

        var _elt           = elt,
            _data    = _elt.data();

        var template = handlebarsDom.compileFromDomID(_data.handlebars);

        $("[data-modalrequestajax='frame']").html(template(_data));
        $("[data-modalrequestajax='frame'] > div").modal({show:true});

    }
    modalExtend.requestHandlebars=requestHandlebars;

    /**
     * @memberOf requestAjax
     */
    function  requestAjax(_array) {
        $("[data-modalrequestajax='frame']").addClass("isModalRequestAjax");

        _array.elt.prop("disabled",true).addClass("loading");

        setTimeout(function(){
            $.ajax({
                url: _array.url,
                type: _array.type,
                data: _array.data,
                datatype: _array.datatype,
                success: function(data){
                	// a response with a link with the id="redirect" signals that this href should be loaded
                    // this is used to make a https redirect to the cart that only works with https
                	if ($(data).filter('a#redirect').length == 1) {
                		window.location.href = $(data).filter('a#redirect').attr(('href'));
                	}
                	else {
                		$("[data-modalrequestajax='frame']").removeClass("isModalRequestAjax").html(data);
                		$("[data-modalrequestajax='frame'] > div").modal({show:true});
                		_array.elt.prop("disabled",false).removeClass("loading");
                	}
                }
            });
        },1000);
    }
    modalExtend.requestAjax=requestAjax;

    /**
     * @memberOf dispatch
     */
    function  dispatch(elt) {
        var _elt    = elt,
            _params =  $.extend({},elt.data()),
            _array  = {};


        switch (_params["modalrequest"]) {
            case "success:form:validator":
                _array = {
                    elt      : _elt,
                    type     : "post",
                    datatype : "text/html",
                    url      : _elt.attr('action'),
                    data     : _elt.serialize()+"&"+$("button[type=submit]",_elt).attr('name')
                };
                break;
            case "ajax:submit":
                _array = {
                    elt      : _elt,
                    type     : "post",
                    datatype : "text/html",
                    url      : _elt.parents('form').attr('action'),
                    data     : _elt.parents('form').serialize()+"&"+_elt.attr("name")
                };
                break;
            case "ajax:click":
                _array  = {
                    elt      : _elt,
                    type     : "get",
                    datatype : "text/html",
                    url      : _params["url"],
                    data     : _params["parameters"]
                };
                break;
            default:
                _array = {error:true}
        }
        modalExtend.requestAjax(_array);
    }
    modalExtend.dispatch=dispatch;

    /**
     * @memberOf eventsHandlers
     */
    function  eventsHandlers() {

        $(document).find('*[data-modallaunch="true"]').once("modalLaunch").each(function (key, value) {
            $(this).modal('toggle');
        });

        $('[data-modalrequest="ajax:submit"]').once("modalrequestajax").on("submit", function(e) {
        	e.preventDefault();
            if(!$("[data-modalrequestajax='frame']").hasClass("isModalRequestAjax")){
                modalExtend.dispatch($(this));
                return false;
            }
        });
        
        $('[data-modalrequest="ajax:click"]').once("modalrequestajax").on("click", function(e) {
        	e.preventDefault();
            if(!$("[data-modalrequestajax='frame']").hasClass("isModalRequestAjax")){
                modalExtend.dispatch($(this));
            }
        });

        $('[data-modalrequest="handlebars:click"]').once("modalrequesthandlebars").on("click", function(e) {
        	e.preventDefault();
            if(!$("[data-modalrequestajax='frame']").hasClass("isModalRequestAjax")){
                modalExtend.requestHandlebars($(this));
            }
        });

        $(document).on('success.form.bv', '*[data-modalrequest="success:form:validator"]', function(e) {
            e.preventDefault();
            if(!$("[data-modalrequestajax='frame']").hasClass("isModalRequestAjax")){
                modalExtend.dispatch($(this));
                return false;
            }
        });

    }
    modalExtend.eventsHandlers=eventsHandlers;


    /**
     * @memberOf init
     */
    function  init() {
        $('body').append("<div data-modalrequestajax='frame'></div>");
        modalExtend.eventsHandlers();

    }
    modalExtend.init=init;

})();



$(document).ready( function(){
    modalExtend.init();
});

$(document).ajaxComplete(
    function() {
        modalExtend.eventsHandlers();
    }
);