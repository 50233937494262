function dtAccountApportsTable() {
    return {
        setup: function () {
            this.DOM = {
                tables: qsa('[data-dt-account]', this.element)
            };

            this.cfg = {
                heightAnimation: false,
            };

            this.state = {
                showHidesBelowListeners: false,
            };

            this.constructTable();
        },

        cleanup: function () {
            this.cleanupEvents();
            this.element.dispatchEvent(new Event('afterCleanup'));
        },

        constructTable: function(){
            const that = this;

            this.DOM.tables.forEach(function(table){
                const getTemplate = table.getAttribute('data-dt-account-template'),
                    getSource = table.getAttribute('data-dt-account-source'),
                    HBS_SubTableLevel = handlebarsDom.compileFromDomID(getTemplate),
                    subTable = HBS_SubTableLevel(JSON.parse(getSource));

                table.innerHTML = subTable;
                
                const togglers = qsa('[data-toggle-sublevel]', table);
                
                togglers.forEach(function(toggler){
                    toggler.addEventListener('click', that.handleToggleEvent);
                });
            });
            
        },

        cleanupEvents: function () {
            this.DOM.tables.forEach(function(table){
                table.removeEventListener('constructedTable', this.constructTable);
                
                const togglers = qsa('[data-toggle-sublevel]', table);
                togglers.forEach(function (toggler) {
                    toggler.removeEventListener('click', this.handleToggleEvent);
                }, this);
            }, this);
        },

        handleToggleEvent: function (event) {
            event.preventDefault();
            event.stopPropagation();

            const elt = event.target,
                  parentTr = elt.closest('tr'),
                  parentTbody = elt.closest('tbody'),
				  target = parentTr.nextElementSibling;
			
			target.classList.toggle('hidden');
			parentTr.classList.toggle('active');
			parentTbody.classList.toggle('active');

            this.element.dispatchEvent(new Event('afterTrToggle'));
        }
    }
}
