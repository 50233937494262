function dtShowHide() {
    return {
        setup: function () {
            this.DOM = {
                content: qs('[data-show-hide="content"]', this.element),
            };

            this.cfg = {
                heightAnimation: false,
            };

            this.state = {
                showHidesBelowListeners: false,
            };

            this.handleToggleEvent = this.handleToggleEvent.bind(this);

            this.getTogglers(); // Set/update this.DOM.togglers
            this.initEvents();
        },

        cleanup: function () {
            this.cleanupEvents();
            this.element.dispatchEvent(new Event('afterCleanup'));
        },

        getTogglers: function () {
            var otherShowhidesBelow = qsa('[data-dtis="show-hide"]', this.element);

            // Recall this function if showhide below is updated (disabled/enabled)
            if(!this.state.showHidesBelowListeners) {
                this.state.showHidesBelowListeners = true;

                otherShowhidesBelow.forEach(function (showHideBelow) {
                    showHideBelow.addEventListener('afterSetup', this.getTogglers());
                    showHideBelow.addEventListener('afterCleanup', this.getTogglers());
                }, this);
            }

            var listRaw = qsa('[data-show-hide="toggler"]', this.element);
            var listClean = [];

            // Loop on each toggler found & check if not in showhide below
            listRaw.forEach(function (toggler) {
                var keepToggler = true;

                otherShowhidesBelow.forEach(function (showHideBelow) {
                    if (isDescendant(showHideBelow, toggler)) {
                        keepToggler = false;
                    }
                }, this);

                if(keepToggler) {
                    listClean.push(toggler);
                }
            });

            this.DOM.togglers = listClean;
        },

        initEvents: function () {
            this.DOM.togglers.forEach(function (toggler) {
                toggler.addEventListener('click', this.handleToggleEvent);
            }, this);

            this.element.dispatchEvent(new Event('afterSetup'));

        },

        cleanupEvents: function () {
            this.DOM.togglers.forEach(function (toggler) {
                toggler.removeEventListener('click', this.handleToggleEvent);
            }, this);
        },

        handleToggleEvent: function (event) {
            event.preventDefault();
            event.stopPropagation();

            if (this.isContentShown()) {
                if (!this.DOM.content.style.height && this.cfg.heightAnimation) {
                    // if no height given, ensure that CSS transitions work
                    this.DOM.content.style.height = this.DOM.content.firstElementChild.scrollHeight + 'px';
                    this.state.timeoutID = setTimeout((function () {
                        this.hide();
                    }).bind(this), 0);
                } else {
                    this.hide();
                }
            } else {
                this.show();
            }

            this.element.dispatchEvent(new Event('afterToggle'));
        },

        isContentShown: function () {
            return this.DOM.content.getAttribute('data-shown') === 'true';
        },

        hide: function () {
            if (this.cfg.heightAnimation)
                this.DOM.content.style.height = '0px';

            this.element.setAttribute('data-shown', 'false');
            this.DOM.content.setAttribute('data-shown', 'false');

            this.DOM.togglers.forEach(function (toggler) {
                toggler.setAttribute('data-shown', 'false');
            }, this);

            this.element.dispatchEvent(new Event('afterHide'));
        },

        show: function () {
            if (this.cfg.heightAnimation)
                this.DOM.content.style.height = this.DOM.content.firstElementChild.scrollHeight + 'px'; // Todo debug wrong height

            this.element.setAttribute('data-shown', 'true');
            this.DOM.content.setAttribute('data-shown', 'true');

            this.DOM.togglers.forEach(function (toggler) {
                toggler.setAttribute('data-shown', 'true');
            }, this);

            this.element.dispatchEvent(new Event('afterShow'));
        }
    }
}
