/**
 * DT COMPONENTS
 * Instantiate components based on data-dtis="component-name" in DOM
 **/

function components() {
    return {
        initAll: function () {
            this.componentsList = {};
            this.DOMlist = this.getComponents();
            for (var i = 0; i < this.DOMlist.length; i++) {
                this.initComponent(this.DOMlist[i]);
            }
        },

        cleanupAll: function () {
            this.componentsList.forEach(function (component, name) {
                if (!!component && component.hasOwnProperty('cleanup')) {
                    component.cleanup();
                } else {
                    console.error('Component "' + name + '" must have a cleanup() method');
                }
            });
        },

        getComponents: function () {
            return document.querySelectorAll('[data-dtis]');
        },

        getComponent: function (elem, slug) {
            var componentName = camelize(slug);
            var componentID = elem.getAttribute('data-dtid');

            return this.componentsList[componentName + '_' + componentID];
        },

        initComponent: function (elem) {
            var componentNames = elem.getAttribute('data-dtis').split(' ');
            var randomID = this.getRandomId(); // don't set multiple ids if multiple components on same node

            componentNames.forEach(function (componentName) {
                componentName = camelize(componentName);
                var component = window.hasOwnProperty(componentName) ? window[componentName](elem) : false;

                if (!!component && component.hasOwnProperty('setup')) {
                    component.element = elem;
                    component.setup();

                    if (!component.element.getAttribute('data-dtid'))
                        component.element.setAttribute('data-dtid', randomID);

                    this.componentsList[componentName + '_' + randomID] = component;
                }
            }, this);
        },

        getRandomId: function () {
            return Math.floor(Math.random() * Math.floor(100));
        }
    }
}




$(document).ready(function() {
    console.log('dtComponents before launch (after message must happened next)'); // TODO: remove this log
    window.dtComponents = components();
    dtComponents.initAll();
    console.log('dtComponents after launch (success)'); // TODO: remove this log
});
