
var breakPoint = {
        xs:768,
        sm:992,
        md:1440,
        lg:100000
}


/**
 * @namespace
 */
var bpoint = {


    isBreakpointXS : function() {

        return ((window.innerWidth >= 0) && (window.innerWidth < breakPoint.xs));
    },

    isBreakpointSM : function() {

        return ((window.innerWidth >= breakPoint.xs) && (window.innerWidth < breakPoint.sm));
    },

    isBreakpointSMup : function(spread) {

        return (window.innerWidth >= breakPoint.xs);
    },

    isBreakpointSMdown : function() {

        return (window.innerWidth < breakPoint.sm);
    },

    isBreakpointMD : function() {

        return ((window.innerWidth >= breakPoint.sm) && (window.innerWidth < breakPoint.md));
    },

    isBreakpointMDup : function() {

        return (window.innerWidth >= breakPoint.sm);
    },

    isBreakpointMDdown : function() {

        return (window.innerWidth < breakPoint.md);
    },

    isBreakpointLG : function() {

        return (window.innerWidth >= breakPoint.md);
    },
    getBreakpoint : function(){
        if(this.isBreakpointXS){return "xs";}
        else if(this.isBreakpointSM){return "sm";}
        else if(this.isBreakpointMD){return "md";}
        else if(this.isBreakpointLG){return "lg";}
        else{return false;}
    },
    isBreakpointByValue : function(bp){
        if(bp == "xs"){return this.isBreakpointXS();}
        else if(bp == "sm"){return this.isBreakpointSM();}
        else if(bp == "md"){return this.isBreakpointMD();}
        else if(bp == "lg"){return this.isBreakpointLG();}
        else{return false}
    }

 };