function dtSearch() {
    return {
        setup: function () {
            this.DOM = {
                togglers: qsa('[data-search="toggler"]', this.element),
                content: qs('[data-search="content"]', this.element),
                searchInput: qs('input.searchTerm', this.element),
                searchResults: qs('[data-search="results"]', this.element),
            };

            this.state = {
                toggled: false,
            };

            this.toggleSearchPanel = this.toggleSearchPanel.bind(this);
            this.setupEvents();
        },

        cleanup: function () {
            this.cleanupEvents();
        },

        setupEvents: function () {
            this.DOM.togglers.forEach( function (toggler) {
                toggler.addEventListener('click', this.toggleSearchPanel);
            }, this);
        },

        cleanupEvents: function () {
            this.DOM.togglers.forEach( function (toggler) {
                toggler.removeEventListener('click', this.toggleSearchPanel);
            }, this);
        },

        toggleSearchPanel: function (event) {
            event.preventDefault();

            // Cleanup search results on toggle
            if (this.DOM.searchResults.innerHTML !== '') {
                this.DOM.searchResults.innerHTML = '';
                this.DOM.searchInput.value = '';
            }

            this.state.toggled = !this.state.toggled;

            // Focus in search input
            if (this.state.toggled)
                this.DOM.searchInput.focus();

            this.element.setAttribute('data-search-toggled', this.state.toggled);
            this.DOM.content.setAttribute('data-search-toggled', this.state.toggled);

            this.DOM.togglers.forEach( function (toggler) {
                toggler.setAttribute('data-search-toggled', this.state.toggled);
            }, this);
        },
    }
}