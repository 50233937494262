var Notifications = (function() {
    function remove() {
        $('*[data-notifications-card]').remove();
    }
    function display(data) {
        remove();
        var template = handlebarsDom.compileFromDomID(data.notificationsHandlebars);
        $('body').append(template(data));
    }
    function events(){
        $(document).on('click','*[data-notifications-events="click"]', function(event) {
            display($(this).data());
        });
        $(document).on('success.form.bv', '*[data-notifications-events="form:success"]', function(event) {
            display($(this).data());
        });
    }
    function init(){
        events();
    }
    return {
        init: init,
        events:events,
        display:display,
        remove:remove
    };
})();

$(document).ready( function() {
    Notifications.init();
});

$(document).ajaxComplete( function() {
    //Notifications.init();
});