'use strict';
//import { HandlebarsDom } from "modules/handlebars/handlebars-dom.js";

var WebServices = (function() {

    function WebServices(){}

    WebServices.getTemplate = function (idtemplate,data){
        return handlebarsDom.compileFromDomID(idtemplate)(data);
    }

    WebServices.dispatch = function (elt,json){

        switch (json.webservices) {
            case "products":
                $.each(json.results, function (key, obj) {
                    $.each(json.results[key], function (view, obj) {
                        var data = json.results[key][view];
                        var handlebarsParameters  = elt.data("webservices-handlebarsparameters");
                        if(handlebarsParameters != undefined){
                            $.each(handlebarsParameters,function(key,value){
                                data[key] = value;
                            })
                        }
                        $("[data-ws-target='"+key+":"+view+"']").html(WebServices.getTemplate("HBS_"+view,data));
                    });
                });
                break;
            case "multiple":
                $.each(json.results, function (view, obj) {
                    var data = json.results[view];
                    var handlebarsParameters  = elt.data("webservices-handlebarsparameters");
                    if(handlebarsParameters != undefined){
                        $.each(handlebarsParameters,function(key,value){
                            data[key] = value;
                        })
                    }
                    $("[data-ws-target='"+view+"']").html(WebServices.getTemplate("HBS_"+view,data));
                });
                break;
            default:
                var data = json;
                var handlebarsParameters  = elt.data("webservices-handlebarsparameters");
                if(handlebarsParameters != undefined){
                    $.each(handlebarsParameters,function(key,value){
                        data[key] = value;
                    })
                }
                elt.html(WebServices.getTemplate(elt.data("webservices-handlebarsid"),data)).removeClass("isLoading");
                break;
        }

    }

    WebServices.request = function (elt,url){

        $.ajax({
            method: "GET",
            url:url,
            data:"",
            dataType: "json",
            success: function(json){
                setTimeout(function(){
                    WebServices.dispatch(elt,json);
                },"10");
            },
            error: function(data,err,txt){
                /*console.log(data);
                console.log(err);
                console.log(txt);*/
            },
            complete: function(){}
        });

    }

    WebServices.init = function (){
        $(document).find('*[data-webservices]').once('webservices').each(function (key, value) {
            var _data = $(this).data();
            WebServices.request($(this),_data.url);
        });
    }

   return WebServices;


})();



var AsyncRequest = (function() {

    function AsyncRequest(){}

    AsyncRequest.getTemplate = function (idtemplate,data){
        return handlebarsDom.compileFromDomID(idtemplate)(data);
    }

    AsyncRequest.display = function (elt,result){
        const conf = elt.data();
        switch (conf.asyncrequestDatatype) {
            case "html":
                $("[data-target='"+conf.asyncrequestTarget+"']").html(result);
                break;
            default:
                $("[data-target='"+conf.asyncrequestTarget+"']").html(AsyncRequest.getTemplate("HBS_"+conf.asyncrequestHandlebars,result));
                break;
        }

    }

    AsyncRequest.request = function (elt){
        const conf = elt.data();
        $.ajax({
            method: (conf.asyncrequestMethod) ? conf.asyncrequestMethod : "GET",
            url:conf.asyncrequestUrl,
            data:(conf.asyncrequestData) ? conf.asyncrequestData : "",
            dataType: (conf.asyncrequestDatatype) ? conf.asyncrequestDatatype : "json",
            success: function(result){
                AsyncRequest.display(elt,result);
            },
            error: function(data,err,txt){
                /*console.log(data);
                console.log(err);
                console.log(txt);*/
            },
            complete: function(){}
        });

    }

    AsyncRequest.init = function (){
        $(document).on('click', '*[data-asyncrequest]', function(event) {
            AsyncRequest.request($(this));
        });
    }

    return AsyncRequest;


})();

$(document).ready( function(){
    WebServices.init();
    AsyncRequest.init();
});
$(document).ajaxComplete(function() {
    WebServices.init();
});


