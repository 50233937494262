function dtAccountNavigation() {
    return {
        setup: function () {
            this.DOM = {
                wrapper: qs('[data-account-navigation="wrapper"]', this.element),
                burger: qs('[data-account-navigation="burger-toggler"]'),
                layerClose: null, // affected when layer is instanciated on small screen
            };

            this.state = {
                screenSize: null, // updated by this.checkDisplay() => "large" or "small"
                burgerSetup: false, // burger display on mobile
            }

            this.layer = dtComponents.getComponent(document.body, 'dt-layer');

            this.checkDisplay = this.checkDisplay.bind(this);
            this.toggleBurger = this.toggleBurger.bind(this);

            this.checkDisplay();
            document.addEventListener('dtviewresize', this.checkDisplay);
        },

        cleanup: function () {
            document.removeEventListener('dtviewresize', this.checkDisplay);
        },

        checkDisplay: function () {
            const screenSize = getDisplay(this.element);

            if (this.state.screenSize !== screenSize) {
                this.state.screenSize = screenSize;

                switch (screenSize) {
                    case 'large':
                        this.cleanupBurger();
                        break;

                    case 'small':
                        this.setupBurger();
                        break;
                }
            }
        },

        setupBurger: function () {
            if (!!this.state.burgerSetup)
                return;

            this.DOM.burger.addEventListener('click', this.toggleBurger);

            this.state.burgerSetup = true;
        },

        cleanupBurger: function () {
            if (!this.state.burgerSetup)
                return;

            this.layer.cleanup();
            this.DOM.burger.removeEventListener('click', this.toggleBurger);
            this.state.burgerSetup = false;
        },

        toggleBurger: function () {
            this.layer.cleanup();

            if (!this.layer.state.toggled) {
                this.layer.show(this.DOM.wrapper, 'account');

                if (!this.state.listenLayerClose) {
                    this.state.listenLayerClose = true;
                }
            } else {
                this.layer.hide();
            }
        }
    }
}