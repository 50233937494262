/**
 * Collapsing of product search results or content search results
 **/
$(document).on( 'click', '.search-switch-toggle', function(e){
    var target = $(e.target);
    var switchTargetID = target.data("switch-target-id");
    $("#" + switchTargetID).show();
    var hideTargetID = target.data("hide-target-id");
    $("#" + hideTargetID).hide();
});
