var IsAnimate = false;
var desktop=false;
var mobile=false;
var tablet=false;



var QBox = {

    init: function( options, elem ) {
        var self = this;
        self.clickOverHandler = self.qBoxClickOver();
        self.elem = elem;
        self.obj = $(elem);
        self.btn = $(elem).find("*[data-qbox-btn]");
        self.wrapper = $(elem).find("*[data-qbox-wrapper]");
        //self.wrapper.css("top",self.btn.outerHeight());
        self.params =  $.extend({},self.obj.data());
        self.options = $.extend( {}, $.fn.qBox.options, options );
        self.event = (self.params["qboxEvent"] != undefined) ? "mouse" : "click";
        var _qboxWidth = (self.params["qboxWidth"] != undefined) ? self.params["qboxWidth"] : "auto";
        self.wrapper.css("width",_qboxWidth);
        self.action();
    },

    action: function() {
        var self = this;
        self.obj.clearQueue();
        if(self.event == "click" && bpoint.isBreakpointSMup()){
            self.btn.on("click",function(e){
                e.preventDefault();
                if(!self.obj.hasClass("isActive")){
                    self.show();
                }else if(!self.obj.hasClass("isLoading")){
                    self.hide();
                }
            });


        }else if(self.event == "mouse"){
            self.obj.hover(
                function() {
                    $(this).clearQueue();
                    if(!self.obj.hasClass("isActive")){
                        self.show();
                    }
                },
                function() {
                    $(this).delay(500).queue(
                        function(){
                            self.hide();
                        }
                    );
                }
            );
        }

    },

    qBoxClickOver : function() {
        var self = this;
        return (function(e) {

            if ($(e.target).parents("[data-qbox]").length)
                return

            if (self.obj.hasClass("isActive")) {
                if (! self.obj.hasClass("isLoading")) {
                    self.hide();
                }
            }

        });
    },

    show: function() {
        var self = this;
        $( "*[data-qbox].isActive" ).removeClass("isActive");
        self.obj.addClass("isActive");
        if(self.params["qboxUrl"]!=undefined){
            self.loadTemplate();
        }
        $(document).on( "click", "*", self.clickOverHandler );
    },

    hide: function() {
        var self = this;
        if(!self.obj.find("input").is(":focus")){
            self.obj.removeClass("isActive");
        }
        $(document).off( "click", "*", self.clickOverHandler );
    },

    active : function(obj){
        var self = this;
    },
    renderTemplate : function(id,param1,param2,param3){
        return Handlebars.compileFromDomID(id)({param1:param1,param2:param2,param3:param3});
    },
    loadTemplate : function(){
        var self = this;
        //var _progessbar = $('<div class="progressbar blue"><span></span></div>');
        var _progessbar = $('<div class="load_full" style="display:block;"></div>');
        self.wrapper.html("").append(_progessbar);
        self.obj.addClass("isLoading");
        $.ajax({
            type: "GET",
            url: self.params["qboxUrl"],
            success: function(data){
                setTimeout(function(){
                    self.obj.removeClass("isLoading");
                    _progessbar.remove();
                    self.wrapper.html(data);
                }, 200);
            }
        });

    }
};


$.fn.qBox = function( options ) {
    return this.each(function() {
        var qBox = Object.create( QBox );
        qBox.init( options, this );
        $.data( this, 'qBox', qBox );
    });
};

$.fn.qBox.options = {
    url:"",
    css:""
};






function qboxInit(){
    $(document).find("[data-qbox]").once('qbox').each(function (key, value) {
        var params =  $.extend({},$(value).data());
        $(value).qBox({
            url : (params["qboxUrl"]) ? params["qboxUrl"]  : "",
            css : (params["qboxCss"]) ? params["qboxCss"]  : ""
        });
    });
};



$(document).ready( function(){
    qboxInit();
});

$(document).ajaxComplete(
    function() {
        qboxInit();
    }
);