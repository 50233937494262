var CustomActions = {};
(function() {
    CustomActions.eventsOnChange = function() {
        $('*[data-customaction-onchange]').on("change",function (key) {
            $(this).parents("form").submit();
        });
    }
    CustomActions.toggleOnSubmit = function() {
        $(document).on("click","*[data-customaction-toggle-submit]",function (key,value) {
            key.stopImmediatePropagation();
            key.preventDefault();
            console.log('key:',key);
            console.log('this:',$(this));
            CustomActions.requestAjax(buildAjaxData($(this)));
            return false;
        });
    }
    CustomActions.build = function() {
       
        if($("[data-customaction-onchange]").length){
            CustomActions.eventsOnChange();
        }
        CustomActions.toggleOnSubmit();
        
    }
    CustomActions.requestAjax = function(_array) {
        
            $.ajax({
                url: _array.url,
                type: _array.type,
                data: _array.data,
                datatype: _array.datatype,
                success: function(data){
                    $('#'+_array.targetID).html(data);
                },
                beforeSend: function(){
                    $('#AskCodePhoneMobileFirstVisit').attr('disabled', 'disabled');
                }
            });
        
    }
    
    function buildAjaxData(elt) {
        var  _array  = {}, _elt=elt;
        _array = {
                elt      : _elt,
                type     : "post",
                datatype : "text/html",
                url      : _elt.parents('form').attr('action'),
                data     : _elt.parents('form').serialize()+"&"+_elt.attr("name"),
                targetID : _elt.parents('form').attr('data-customaction-toggle-submit-id')
            };
        return _array;
    }
    CustomActions.buildAjaxData=buildAjaxData;
})();


$(document).ready( function(){
    CustomActions.build();
});
