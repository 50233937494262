$.fn.once = function(flag) {
    if ( flag === undefined ) {
        flag="once";
    }
    flag += "flag";
    return  $(this).filter(function() {
        return !($(this).data(flag))
    }).data(flag,true);
};

$.fn.resetOnce = function(flag) {
    if ( flag === undefined ) {
        flag="once";
    }
    flag += "flag";
    return  $(this).data(flag,false);
};