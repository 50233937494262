var MenuPanel = {};
(function() {

    MenuPanel.events = function() {
        $(document).on('click', '[data-menupanel-toggle]', function(event){
            $("[data-menupanel]").toggleClass('isActive');
            $("[data-menupanel-inner]").html($("[data-menupanel-clone]").html());
        });
    }

    /**
     * @memberOf MenuPanel
     */
    MenuPanel.build = function() {
        MenuPanel.events();
    }

})();

$(document).ready( function(){
    MenuPanel.build();
});
