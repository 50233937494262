var SelectSearch = {};
(function() {

    /**
     * @memberOf SelectSearch
     */
    SelectSearch.request = function(obj) {
        var listItem = $('*[data-selectsearch-item]');
        var index = listItem.index( obj );
        var dataObj = obj.data();
        var urlRequest = $('*[data-selectsearch-node="form"]').attr("action");
        
        if(dataObj.selectsearchAction == "updateselect"){
            var selectToUpdate = $("[name='"+obj.data("selectsearch-update")+"']");
            selectToUpdate.empty();
        }
        
        var dataRequest = $('*[data-selectsearch-node="form"]').serialize();
        listItem.prop("disabled",true);
        $('*[data-selectsearch-node=table]').empty().append('<div class="placeholder"><div class="line"></div><div class="line"></div><div class="line"></div><div class="line"></div></div>');
        $.ajax({
            url: urlRequest,
            type: "GET",
            data: dataRequest,
            datatype: "json",
            success: function(json){
                obj.prop("disabled",false);
                setTimeout(function(){
                    if(dataObj.selectsearchAction == "updateselect"){
                        var selectToUpdate = $("[name='"+obj.data("selectsearch-update")+"']");
                        selectToUpdate.empty();
                        selectToUpdate.append("<option disabled selected>"+selectToUpdate.data("selectsearch-groupname")+"</option>");
                        $.each(json.data.results, function(i, item) {
                            selectToUpdate.append("<option value='"+item.value+"'>"+item.name+"</option>");
                        });
                        selectToUpdate.prop("disabled",false);
                        listItem.each(function(key,value){
                            if(key < (index) ){
                                $(value).prop("disabled",false);
                            }
                            if(key > (index + 1) ){
                                $(value).empty().append("<option disabled selected>"+$(value).data("selectsearch-groupname")+"</option>").prop("disabled",true);
                            }
                        });

                        var HBS_SelectSearchTable = handlebarsDom.compileFromDomID("HBS_SelectSearchTableNoResult");
                        $("*[data-selectsearch-node='table']").html(HBS_SelectSearchTable(json));

                    }else if(dataObj.selectsearchAction == "updatetable"){
                        var HBS_SelectSearchTable = handlebarsDom.compileFromDomID("HBS_SelectSearchTableResult");
                        $("*[data-selectsearch-node='table']").html(HBS_SelectSearchTable(json));
                        listItem.prop("disabled",false);
                    }
                },300);
            }
        });
    }

    /**
     * @memberOf SelectSearch
     */
    SelectSearch.events = function() {
        $('*[data-selectsearch-item]').on("change",function (key) {
            SelectSearch.request($(this));
        });
    }

    /**
     * @memberOf SelectSearch
     */
    SelectSearch.build = function() {
        // Change from $("[data-selectsearch-init]") to $("[data-selectsearch-init]").length because jQuery always returns an object (even when empty) when asked to checked for an element, which is truthy.
        if($("[data-selectsearch-init]").length){
            SelectSearch.request($("[data-selectsearch-init]"));
            SelectSearch.events();
        }
    }

})();


$(document).ready( function(){
    SelectSearch.build();
});
