function hbsHelpers(hbs) {
    hbs.registerHelper('for', function(from, to, incr, block) {

        var accum = '';
        for(var i = from; i <= to; i += incr)
            accum += block.fn(i);
        return accum;
    });

    hbs.registerHelper('compare', function(lvalue, rvalue, options) {

        if (arguments.length < 3)
            throw new Error("Handlerbars Helper 'compare' needs 2 parameters");

        var operator = options.hash.operator || "==";

        var operators = {
            '==':       function(l,r) { return l == r; },
            '===':      function(l,r) { return l === r; },
            '!=':       function(l,r) { return l != r; },
            '<':        function(l,r) { return l < r; },
            '>':        function(l,r) { return l > r; },
            '<=':       function(l,r) { return l <= r; },
            '>=':       function(l,r) { return l >= r; },
            'typeof':   function(l,r) { return typeof l == r; }
        }

        if (!operators[operator])
            throw new Error("Handlerbars Helper 'compare' doesn't know the operator "+operator);

        var result = operators[operator](lvalue,rvalue);

        if( result ) {
            return options.fn(this);
        } else {
            return options.inverse(this);
        }

    });

    hbs.registerHelper("math", function(lvalue, operator, rvalue, options) {
        lvalue = parseFloat(lvalue);
        rvalue = parseFloat(rvalue);
        return {
            "+": lvalue + rvalue,
            "-": lvalue - rvalue,
            "*": lvalue * rvalue,
            "/": lvalue / rvalue,
            "%": lvalue % rvalue
        }[operator];
    });

    hbs.registerHelper('raw-helper', function(options) {
        return options.fn();
    });

    hbs.registerHelper('isinclude', function(sentence,word,options) {

        if (arguments.length < 3)
            throw new Error("Handlerbars Helper 'isInclude' needs 2 parameters");

        var result = sentence.includes(word);


        if( result ) {
            return options.fn(this);
        } else {
            return options.inverse(this);
        }

    });
    hbs.registerHelper('arrayify', function(value){
        return value ? (Array.isArray(value) ? value : [value]) : [];
    });

    hbs.registerHelper('split', function(str, ch) {
        if (!util.isString(str)) return '';
        if (!util.isString(ch)) ch = ',';
        return str.split(ch);
    });

    hbs.registerHelper('if_eq', function(a, b, opts) {
        if (a === b) {
            return opts.fn(this);
        } else {
            return opts.inverse(this);
        }
    });

    function setVariable(varName, varValue, options){
        options.data.root[varName] = varValue;
    };

}

hbsHelpers(Handlebars);