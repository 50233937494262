var nodemove = {};
(function() {
    nodemove.moveto = function() {
        $('*[data-nodemove-moveto]').each(function () {
            var $this = $(this);
            var config = $this.data("nodemove-moveto");
            $.each(config, function(i, item) {
                item.breakpoint.split("|").forEach(function(value, index, array){
                    if(bpoint.isBreakpointByValue(value)){
                        $this.appendTo($("[data-nodemove-spot='"+item.spot+"']"));
                    }
                });
            });
        });
    }
    nodemove.events = function() {
        window.onresize = function(event) {
            nodemove.moveto();
        };
    }
    nodemove.init = function() {
        nodemove.moveto();
        nodemove.events();
    }
    return nodemove;
})();


$(document).ready( function(){
    nodemove.init();
});

$(document).ajaxComplete(
    function() {nodemove.init();}
);