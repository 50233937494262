/**
 * Utils
 **/

// Camelize a string
function camelize(str) {
    return str.replace(/\W+(.)/g, function (match, chr) {
        return chr.toUpperCase();
    });
}

// Query selector single
function qs(selector, element) {
    var scope = !!element ? element : document;
    var elem = scope.querySelector(selector);
    return !!elem ? elem : false;
}

// Query selector multiple
function qsa(selector, element, options) {
    var scope = !!element ? element : document;
    var elems = scope.querySelectorAll(selector);

    if (!options || (options && !options.hasOwnProperty('node')))
        elems = Array.prototype.slice.call(elems);

    if (options && options.hasOwnProperty('associative')) {
        var oldelems = elems;
        elems = {};
        oldelems.forEach(function (node) {
            var key = node.getAttribute('id');
            if (key) {
                elems[key] = node;
            } else {
                console.error('You must specify an id attribute to generate an associative array !');
            }
        });
    }
    return elems;
}

// Is element descendant of another
function isDescendant(parent, child) {
    var node = child.parentNode;
    while (node != null) {
        if (node == parent) {
            return true;
        }
        node = node.parentNode;
    }
    return false;
}

// Debounce
function debounce(fn, threshold, isAsap) {
    var timeout, result;

    function debounced() {
        var args = arguments, context = this;

        function delayed() {
            if (!isAsap) {
                result = fn.apply(context, args);
            }

            timeout = null;
        }

        if (timeout) {
            clearTimeout(timeout);
        } else if (isAsap) {
            result = fn.apply(context, args);
        }

        timeout = setTimeout(delayed, threshold);

        return result;
    }

    return debounced;
}

// Throttle
function throttle(fn, delay) {
    var context, timeout, result, args, diff;
    var prevCall = 0;

    function delayed() {
        prevCall = Date.now();
        timeout = null;
        result = fn.apply(context, args);
    }

    function throttled() {
        context = this;
        args = arguments;
        diff = delay - (Date.now() - prevCall);

        if(diff <= 0) {
            clearTimeout(timeout);
            delayed();
        } else if(! timeout) {
            timeout = setTimeout(delayed, diff);
        }

        return result;
    }

    throttled.cancel = function() {
        clearTimeout(timeout);
    };

    return throttled;
}

// Get displays
function getDisplay(element) {
    var scope = !!element ? element : document;
    var elms = qsa('[data-dtdisplay]', scope);
    var i = elms.length;
    var styles;

    while (i--) {
        styles = getComputedStyle(elms[i], null);

        if (styles.display === 'block') {
            return elms[i].getAttribute('data-dtdisplay');
        }
    }

    return null;
}

// CustomEvent polyfill (IE > 9)
(function () {
    if ( typeof window.CustomEvent === "function" ) return false;

    function CustomEvent ( event, params ) {
        params = params || { bubbles: false, cancelable: false, detail: null };
        var evt = document.createEvent( 'CustomEvent' );
        evt.initCustomEvent( event, params.bubbles, params.cancelable, params.detail );
        return evt;
    }

    window.CustomEvent = CustomEvent;
})();

// Setup debounced viewResize event
var handler = debounce(handleResizeEvent, 100);

window.addEventListener('resize', handler);
window.addEventListener('orientationchange', handleResizeEvent);
if ('orientation' in screen) {
    screen.orientation.addEventListener('change', handleResizeEvent);
}

function handleResizeEvent() {
    var event = new CustomEvent('dtviewresize');

    document.dispatchEvent(event);
}

// Setup throttled scroll event
window.addEventListener('scroll', throttle(handleScrollEvent, 50));

function handleScrollEvent() {
    var event = new CustomEvent('dtviewscroll');

    document.dispatchEvent(event);
}

