var MiniCart = {};
(function() {

    MiniCart.request= function(elt) {
        var $elt = elt,
            configData = $elt.data();
        $.ajax({
            url: configData.minicartRequest,
            type: "GET",
            datatype: "json",
            success: function(json){
                var json = $.parseJSON(json);
                switch (json.action) {
                    case "update":
                        MiniCart.update(json);
                        break;
                    default:
                        //default;
                }
            }
        });
    }
    MiniCart.update = function(json) {
        var HBS_MiniCartLink = handlebarsDom.compileFromDomID("HBS_MiniCartLink");
        var HBS_MiniCartPanel = handlebarsDom.compileFromDomID("HBS_MiniCartPanel");
        $("*[data-minicart-node='MiniCartLink']").html(HBS_MiniCartLink(json.minicart));
        $("*[data-minicart-node='MiniCartPanel']").html(HBS_MiniCartPanel(json.minicart));
    }

    MiniCart.events = function() {
        $(document).on('click', '[data-minicart-toggle]', function(event){
            $("[data-minicart-panel]").toggleClass('isActive');
        });
        $(document).find('*[data-minicart-request]').once('minicart-request').each(function (key, value) {
            MiniCart.request($(value));
        });
    }

    /**
     * @memberOf MiniCart
     */
    MiniCart.build = function() {
        MiniCart.events();
    }

})();

$(document).ready( function(){
    MiniCart.build();
});
