var uiSpinner = {};
(function() {

    /**
     * @memberOf uiSpinner
     */
    uiSpinner.updateValue=function(elt){
        var nVal = elt.val();
        var modulo = elt.val() % elt.config.step;
        if(isNaN(nVal)){
            nVal = elt.config.step;
        }else if(nVal < 0){
            nVal = parseInt(elt.config.step);
        }else if(nVal > parseInt(elt.config.max)){
            nVal = parseInt(elt.config.max);
        }else if(modulo > 0){
            nVal = (parseInt(nVal) - parseInt(modulo)) + parseInt(elt.config.step);
        }
        elt.val(nVal);
    }

    /**
     * @memberOf uiSpinner
     */
    uiSpinner.build=function(elt){
        var $elt = elt,
            params =  $.extend({},$elt.data());
        $elt.config = [];
        $elt.config.min = (params["spinnerMin"]) ? params["spinnerMin"]  : 0;
        $elt.config.max = (params["spinnerMax"]) ? params["spinnerMax"]  : 10000000000;
        $elt.config.step = (params["spinnerStep"]) ? params["spinnerStep"]  : 1;
        $elt.config.empty = (params["spinnerEmpty"] == false) ? false  : true;

        var _spinner = $elt.spinner({
            icons: {down: "icon icon-less", up: "icon icon-more"},
            step: $elt.config.step,
            min: $elt.config.min,
            max: $elt.config.max,
            create: function(){},
            change: function( event, ui ) {},
            stop: function( event, ui ) {},
            start: function( event, ui ) {},
            spin: function( event, ui ) {}
        }).keyup(
            $.debounce( 500, function(){
                uiSpinner.updateValue($elt);
            })
        );

        if ( $elt.is( ":disabled" ) ) {
            _spinner.spinner( "disable" );
        }
    }

    /**
     * @memberOf uiSpinner
     */
    uiSpinner.init= function(){
        $(document).find('*[data-spinner]').once('spinner').each(function (key, value) {
            uiSpinner.build($(value));
        });
    }

    return uiSpinner;

})();



$(document).ready( function(){
    uiSpinner.init();
});

$(document).ajaxComplete(
    function() {
        uiSpinner.init();
    }
);
