function dtNavigation() {
    return {
        setup: function () {
            this.DOM = {
                list: qs('[data-navigation="list"]', this.element),
                items: qsa('[data-navigation="item"]', this.element),
                parentItems: qsa('[data-parent="true"] > a', this.element),
                submenus: qsa('[data-navigation="submenu"]', this.element),
                productCategoryList: qs('[data-navigation="product-category-list"]', this.element),
                productCategorySubmenus: qsa('[data-navigation="product-category-submenu"]', this.element),
                returnBtn: qs('[data-navigation="return"]')
            };

            this.state = {
                screenSize: false,
            }

            this.infos = {};

            this.header = dtComponents.getComponent(qs('.dt-c-Header'), 'dt-header');

            this.checkDisplay = this.checkDisplay.bind(this);
            this.handleSubmenuShowEvent = this.handleSubmenuShowEvent.bind(this);
            this.handleSubmenuHideEvent = this.handleSubmenuHideEvent.bind(this);
            this.returnBtn = this.returnBtn.bind(this);

            this.checkDisplay();
            this.initEvents();
        },

        cleanup: function () {
            this.cleanupEvents();
        },

        initEvents: function () {
            var that = this;
            document.addEventListener('dtviewresize', this.checkDisplay);

            document.body.addEventListener("click", function (e) {
                if (e.target &&
                    e.target.getAttribute("data-navigation-return") !== null) {
                    that.returnBtn(e);
                }
            });
        },

        cleanupEvents: function () {
            document.removeEventListener('dtviewresize', this.checkDisplay);
        },

        checkDisplay: function () {
            var screenSize = getDisplay(this.element);

            if (this.state.screenSize !== screenSize) {
                this.state.screenSize = screenSize;

                switch (screenSize) {
                    case 'large':
                        this.setupProductsSubmenuHeight();
                        this.cleanupShowHides();
                        break;

                    case 'small':
                        this.cleanupProductsSubmenuHeight();
                        this.setupShowHides();
                        break;
                }
            }
        },

        setupProductsSubmenuHeight: function () {
            var biggerHeight = 0;

            this.DOM.productCategorySubmenus.forEach( function (submenu) {
                var submenuStyles = window.getComputedStyle(submenu);
                var submenuPaddingTop = parseInt(submenuStyles.getPropertyValue('padding-top').split('px')[0], 10);
                // var submenuPaddingBottom = parseInt(submenuStyles.getPropertyValue('padding-bottom').split('px')[0], 10);
                var submenuHeight = submenu.scrollHeight + submenuPaddingTop /**+ submenuPaddingBottom**/;

                if (submenuHeight > biggerHeight)
                    biggerHeight = submenuHeight;
            });

            this.DOM.productCategoryList.style.minHeight = biggerHeight+'px';
        },

        cleanupProductsSubmenuHeight: function () {
            this.DOM.productCategoryList.style.minHeight = '';
        },

        setupShowHides: function () {
            this.DOM.parentItems.forEach( function (item) {
                if (item.nextElementSibling && !!item.nextElementSibling.getAttribute('data-navigation').match(/submenu/g)) {
                    // Prepare attrs
                    item.parentElement.setAttribute('data-dtis', 'dt-show-hide');
                    item.setAttribute('data-show-hide', 'toggler');
                    item.nextElementSibling.setAttribute('data-show-hide', 'content');

                    // Listen all showHides
                    item.parentElement.addEventListener('afterShow', this.handleSubmenuShowEvent);
                    item.parentElement.addEventListener('afterHide', this.handleSubmenuHideEvent);

                    dtComponents.initComponent(item.parentElement);
                }
            }, this);
        },

        cleanupShowHides: function () {
            this.DOM.parentItems.forEach( function (item) {
                if (item.nextElementSibling && item.nextElementSibling.getAttribute('data-navigation') === 'submenu') {
                    if(item.getAttribute('data-dtis') === 'dt-show-hide') {
                        var showHideComponent = dtComponents.getComponent(item, 'dt-show-hide');
                        showHideComponent.cleanup();

                        // Stop listening all togglers
                        item.parentElement.removeEventListener('afterShow', this.handleSubmenuShowEvent);
                        item.parentElement.removeEventListener('afterHide', this.handleSubmenuHideEvent);

                        // Cleanup attrs
                        item.removeAttribute('data-show-hide', 'toggler');
                        item.nextElementSibling.removeAttribute('data-show-hide', 'content');
                        item.removeAttribute('data-dtis', 'dt-show-hide');
                    }
                }
            }, this);

            this.element.style.overflow = '';
        },

        retrieveToggledSubmenuInfos: function (event) {
            var entry = event.currentTarget;
            var content = qs('[data-show-hide="content"]', entry);
            var lvl = parseInt(content.getAttribute('data-navigation-lvl'), 10);
            var parents = [ this.element ];

            if (lvl !== -1) {
                var i = lvl;

                while (i < -1) {
                    i++;
                    parents.push(entry.closest('[data-navigation-lvl="'+ i +'"]'));
                }
            }

            this.infos = {
                entry: entry,
                content: content,
                lvl: lvl,
                parents: parents,
            };

            return this.infos;
        },

        handleSubmenuShowEvent: function (event) {
            var infos = this.retrieveToggledSubmenuInfos(event);

            // Remove scroll on parents
            infos.parents.forEach( function (wrapper) {
                wrapper.scrollTop = 0;
                wrapper.style.overflow = 'hidden';
            });
        },

        handleSubmenuHideEvent: function (event) {
            var infos = this.retrieveToggledSubmenuInfos(event);

            // Restore scroll
            infos.parents.forEach( function (wrapper) {
                wrapper.style.overflow = 'auto';
            });
        },

        returnBtn: function(event){
            var level = event.target.getAttribute('data-navigation-lvl');
            var shownMenuContent = event.target.closest('.dt-c-Navigation-item[data-navigation-lvl="' + level + '"]');
            shownMenuContent.setAttribute('data-shown', false);
        }
    }
}
