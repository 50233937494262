var GoogleMapConstructor = (function() {

    function GoogleMapConstructor(obj) {
        var _self = this;
        _self.selectElement = obj;
        _self.icons = {
            default:_self.selectElement.data("gmap-icon"),
            hover:_self.selectElement.data("gmap-icon-hover")
        };
        _self.views = _self.selectElement.data("gmap-views");
        _self.infoTpl = _self.views.info;
        _self.markerListTpl = _self.views.markerlist;
        _self.markerDetailsTpl = _self.views.markerdetails;
        _self.coords = _self.selectElement.data("gmap-coords");
        _self.openOnClick = _self.selectElement.data("gmap-open-click");
        _self.lastInfowindow = "";
        _self.gmap = {
            id : this.selectElement.data("gmap-id"),
            coords : {
                lat :  (_self.coords) ? Number(_self.coords.lat) : 48.857277,
                lng : (_self.coords) ? Number(_self.coords.lng) : 2.352275,
                zoom : (_self.coords) ? Number(_self.coords.zoom) : 12
            },
            map : ""
        };
        _self.gmap.map = this.buildGmap(this.selectElement.data("gmap-id"));
        _self.markers = {};
        _self.markersOnBounds = {};
        _self.markersVisible = 0;

        _self.gmap.map.addListener('bounds_changed', function(event) {
            _self.showMarkerList();
        });

    }

    GoogleMapConstructor.prototype.getTemplate = function(template,data){
        if(template!=undefined){
            var template = handlebarsDom.compileFromDomID(template);
            return template(data);
        }

        return null;
    }

    GoogleMapConstructor.prototype.buildGmap = function(mapid){

        var _self=this;

        return new google.maps.Map(document.getElementById(mapid), {
            center: {
                lat: _self.gmap.coords.lat,
                lng: _self.gmap.coords.lng
            },
            zoom: _self.gmap.coords.zoom,
            zoomControl:true,
            scrollwheel:  true,
            scaleControl: false,
            streetViewControl: false,
            mapTypeControl: false,
            fullscreenControl: false,
            mapTypeControlOptions: {
                mapTypeIds: ['roadmap', 'satellite', 'hybrid', 'terrain','styled_map']
            }
        });

    }

    GoogleMapConstructor.prototype.zoomGmap = function(zoom){
        var _self=this;
        _self.gmap.map.setZoom(zoom);
    }

    GoogleMapConstructor.prototype.centerGmap = function(pos){
        var _self=this;
        _self.gmap.map.setCenter(pos);
    }

    GoogleMapConstructor.prototype.listMarkersEvent = function(){

        var _self=this;
        $('[data-googlemap-markers="list:item"]').once("markeritemclick").on('click', function (ev) {
            //ev.preventDefault();
        }).on('mouseover', function (ev) {
            ev.preventDefault();
            var _marker = _self.markers[$(this).data("id")].marker;
            _marker.setIcon(_self.icons.hover);
            _marker.setAnimation(google.maps.Animation.BOUNCE);
        }).on('mouseleave', function (ev) {
            ev.preventDefault();
            var _marker = _self.markers[$(this).data("id")].marker;
            _marker.setIcon(_self.icons.default);
            _marker.setAnimation(null);
        });

    }

    GoogleMapConstructor.prototype.geoLoc = function(){
        var _self = this;

        $("[data-googlemap-geoloc]").on('click', function (ev) {
            ev.preventDefault();
            if (navigator.geolocation) {
                navigator.geolocation.getCurrentPosition(function(position) {
                    var pos = {
                        lat: position.coords.latitude,
                        lng: position.coords.longitude
                    };
                    _self.gmap.map.setCenter(pos);
                    _self.gmap.map.setZoom(17);
                }, function() {
                });
            }
        });
    }

    GoogleMapConstructor.prototype.autoComplete = function(){
        var _self = this;

        var autocomplete = new google.maps.places.Autocomplete(document.getElementById('googlemap-autocomplete'))

        autocomplete.bindTo('bounds', _self.gmap.map);
        autocomplete.setFields(['address_components', 'geometry', 'icon', 'name']);
        autocomplete.setComponentRestrictions({'country': ['fr']});

        autocomplete.addListener('place_changed', function() {
            var place = autocomplete.getPlace();
            if (!place.geometry) {
                //window.alert("No details available for input: '" + place.name + "'");
                return;
            }
            if (place.geometry.viewport) {
                _self.gmap.map.fitBounds(place.geometry.viewport);
            } else {
                _self.gmap.map.setCenter(place.geometry.location);
                //_self.gmap.map.setZoom(17);
            }
        });
    };

    GoogleMapConstructor.prototype.geoCoder = function(){
        var _self = this;

        var geocoder = new google.maps.Geocoder();

        document.getElementById('googlemap-geocoder').addEventListener('submit', function(ev) {

            ev.preventDefault();

            var address = document.getElementById('googlemap-autocomplete').value;
            address += ",France";

            geocoder.geocode({'address': address}, function (results, status) {
                if (status === 'OK') {
                    _self.gmap.map.setCenter(results[0].geometry.location);
                    _self.gmap.map.setZoom(10);
                } else {
                    alert('Geocode was not successful for the following reason: ' + status);
                }
            });

        });
    };

    GoogleMapConstructor.prototype.deleteMarker = function(){
        var _self = this;

        $.each(_self.markers, function (i, obj) {
            _self.markers[i].marker.setMap(null);
        });
        _self.markers={};
    };

    GoogleMapConstructor.prototype.pushMarker = function(marker,i){
        var _self = this,
            _marker = marker;
        _marker['gmap_id'] = _self.gmap.id;
        _marker['gmap_idx'] = i;
        _self.markers[i]=_marker;
    };

    GoogleMapConstructor.prototype.buildMarker = function(){
        var _self = this;

        $.each(_self.markers, function (_i, _marker) {

            var _googleMapsMarker = new google.maps.Marker({
                position: {
                    lat: _marker.position.lat,
                    lng: _marker.position.lng
                },
                map: _self.gmap.map,
                icon:(_self.icons.default) ? _self.icons.default : _marker.icon,
                title : _marker.name
            });
            var _infowindow = new google.maps.InfoWindow({
                content: String(_self.getTemplate(_self.infoTpl,_marker))
            });

            _self.markers[_i].infowindow = _infowindow;
            _self.markers[_i].marker = _googleMapsMarker;

            if (_self.openOnClick) {
                google.maps.event.addListener(_googleMapsMarker, 'click', function() {
                    if (_marker.url) {
                        window.open(_marker.url,'_blank');
                    }
                });
            }
            else {
                google.maps.event.addListener(_googleMapsMarker, 'click', function() {
                    _self.infoWindowShow(_i);
                });
                google.maps.event.addListener(_self.gmap.map, 'click', function() {
                    _infowindow.close();
                });
            }

            return _googleMapsMarker;

        });

    };

    GoogleMapConstructor.prototype.updateMarkersSetIconDefault = function(){
        var self = this;
        $.each(self.markers,function(key, value){
            value.marker.setIcon(self.icons.default);
        });
    };

    GoogleMapConstructor.prototype.updateMarkersVisibility = function(){
        var bounds =this.gmap.map.getBounds();
        $.each(this.markers,function(key, value){
            value["visibility"]=false;
            if(bounds.contains(value.marker.position)){
                value["visibility"]=true;
            }
        });
    };

    GoogleMapConstructor.prototype.updateMarkersOnBounds = function(){
        var self = this;
        self.markersOnBounds = {};
        self.markersOnBounds = jQuery.map( self.markers, function( n, i ) {
            if( n.visibility ){return n}
        });
    };

    GoogleMapConstructor.prototype.getMarkersVisible = function(){
        var self = this;
        self.markersVisible=0;
        $.each(self.markers,function(key, value){
            if(value["visibility"]){
                self.markersVisible++
            }
        });
        return self.markersVisible;
    };

    GoogleMapConstructor.prototype.appendMarkersList = function(data){
        var self = this;
        $('[data-googlemap-markers="panel:list"]').html(String(self.getTemplate(self.markerListTpl,data)));
    };

    GoogleMapConstructor.prototype.showMarkerList = function(){
        var self = this;
        self.updateMarkersVisibility();
        self.updateMarkersOnBounds();
        /*if(bpoint.isBreakpointSMdown()){
            self.updateMarkersSetIconDefault();
            self.markersOnBounds[0].marker.setIcon(self.icons.hover);
            self.appendMarkersList({"type":"swiper-slide","results":self.markersOnBounds});
            var markers_swiper = swiper.initOnce($("[data-googlemap-markersswiper]"),"markersswiper");
            markers_swiper.on('slideChange', function () {
                self.updateMarkersSetIconDefault();
                self.markersOnBounds[markers_swiper.activeIndex].marker.setIcon(self.icons.hover);
            });
        }else{
            self.appendMarkersList({"type":"","results":self.markersOnBounds});
        }*/

        self.appendMarkersList({"type":"","results":self.markersOnBounds});

        $('[data-googlemap-markersvisible]').html(self.getMarkersVisible());
        this.listMarkersEvent();
    };

    GoogleMapConstructor.prototype.infoWindowShow = function(i){
        var _self = this;
        var _infowindow = _self.markers[i].infowindow;
        var _marker = _self.markers[i].marker;

        if (_self.lastInfowindow!="") _self.lastInfowindow.close();
        _infowindow.open(_self.gmap.map,_marker);
        _self.lastInfowindow=_infowindow;
    };

    GoogleMapConstructor.prototype.isLoading = function(bool){
        (bool) ? $('[data-googlemap-loading]').addClass("isLoading") : $('[data-googlemap-loading]').removeClass("isLoading");
    };

    return GoogleMapConstructor;

})();

var GoogleMapController = (function() {

    var init=false;
    var loaded = false;
    var callbackStack=[];
    var selectElement = "";

    function constructor() {
        return new GoogleMapConstructor(selectElement);
    }

    function setSelectElement(elt) {
        selectElement = elt;
    }

    function getSelectElement() {
        return selectElement;
    }

    function callbackInit() {
        if (loaded)
            return;
        loaded = true;
        var size=callbackStack.length;
        for (var i=0; i < size; i++) {
            setTimeout(callbackStack.shift(),1);
        }
    }

    function buildScript(apiKey) {
        init = true;
        var globalCbName = "gmaploader_"+Date.now();
        window[globalCbName] =  callbackInit;
        var script_tag = document.createElement('script');
        script_tag.setAttribute("type","text/javascript");
        script_tag.setAttribute("async","");
        script_tag.setAttribute("defer","");
        script_tag.setAttribute("src","https://maps.googleapis.com/maps/api/js?&key="+apiKey+"&libraries=places,geometry&callback="+globalCbName);
        (document.getElementsByTagName("head")[0] || document.documentElement).appendChild(script_tag);
    }

    function start(apiKey, callback) {

        if (loaded) {
            setTimeout(callback,1);
            return;
        }
        else {
            callbackStack.push(callback);
        }

        if ( ! init ) {
            buildScript(apiKey);
        }

    }

    function getTemplate(template,data){
        var template = handlebarsDom.compileFromDomID(template);
        return template(data);
    }

    function display(elt,callback){
        setSelectElement(elt);
        var _views = elt.data("gmap-views");
        var _apiKey = elt.data("gmap-key");

        if(elt.data("gmap-display") == "modal"){
            $("body").addClass("isOverflowHidden");
            $('[data-googlemap-modal="dom:widget"]').addClass("isActive");
            $('[data-googlemap-modal="dom:view"]').html(getTemplate(_views.map));
        }else{
            $('[data-googlemap="dom:view"]').html(getTemplate(_views.map));
        }

        start(_apiKey,callback);
    }

    return {
        start: start,
        display:display,
        constructor:constructor,
        getSelectElement : getSelectElement
    };

})();