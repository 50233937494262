var ToggleTabs = {};
(function() {

    ToggleTabs.events = function() {
        $('*[data-toggletabs-btn]').once("data-toggletabs-btn").on('click', function(){

            var config = $(this).data();
            $(config.toggletabsBtn.wrapper).find('[data-toggletabs-btn]').removeClass(config.toggletabsBtn.activeClass);
            $(this).toggleClass(config.toggletabsBtn.activeClass, !$(this).hasClass(config.toggletabsBtn.activeClass));

            $.each(config.toggletabsBtn.show,function(i, item) {
                $('*[data-toggletabs-panel="'+item+'"]').show();
            });
            $.each(config.toggletabsBtn.hide,function(i, item) {
                $('*[data-toggletabs-panel="'+item+'"]').hide();
            });

            if($("[name='addProductConfigurable']")){
                $("[name='addProductConfigurable']").prop("disabled",false);
            }


        });
    }

    /**
     * @memberOf ToggleTabs
     */
    ToggleTabs.init = function() {
        ToggleTabs.events();
    }

})();


$(document).ready( function(){
    ToggleTabs.init();
});

$(document).ajaxComplete(
    function() {
        ToggleTabs.init();
    }
);
