function dtAccountCharts() {
    return {
        setup: function () {
            this.DOM = {
                charts: qsa('[data-chartjs]', this.element)
            };

            this.cfg = {
                heightAnimation: false,
            };

            this.state = {
                showHidesBelowListeners: false,
            };

            this.initChart();
        },

        cleanup: function () {
            this.cleanupEvents();
            this.element.dispatchEvent(new Event('afterCleanup'));
        },

        initChart: function(){
            const that = this;
            
            this.DOM.charts.forEach(function(chart){
                const percentageArray = [],
                    chartKind = chart.getAttribute('data-chartjs-kind'),
                    chartType = chart.getAttribute('data-chartjs-type'),
                    rawshippedDataArray = JSON.parse(chart.getAttribute('data-chartjs-raw-shipped-data')),
                    rawAffectedDataArray = JSON.parse(chart.getAttribute('data-chartjs-raw-affected-data')),
                    rawTotalDataArray = JSON.parse(chart.getAttribute('data-chartjs-raw-total-data')),
                    rawPriceArray = (chart.getAttribute('data-chartjs-raw-price-data')) ? JSON.parse(chart.getAttribute('data-chartjs-raw-price-data')) : '',
                    labels = JSON.parse(chart.getAttribute('data-chartjs-label')),
                    datasets = JSON.parse(chart.getAttribute('data-chartjs-datasets')),
                    fontPath = chart.getAttribute('data-chartjs-fontpath'),
                    getXTickValue = (chart.getAttribute('data-chartjs-x-tick-value')) ? chart.getAttribute('data-chartjs-x-tick-value') : '',
                    ctx = chart.getContext('2d');

                function percentageMaxed(val1, val2, limit) {
                    return Math.min(val1 * 100 / val2, limit) | 0;
                }

                if (rawshippedDataArray && rawTotalDataArray){
                    for (let i = 0; i < rawshippedDataArray.length; i++){
                        percentageArray.push(percentageMaxed(rawshippedDataArray[i], rawTotalDataArray[i], 100));
                    }
                }

                if(chartType === 'doughnut') {
                    const addTextCenterDoughnutPlugin = {
                        id: 'addTextCenterDoughnut',
                        beforeDraw: (chart, args, options) => {
                            if (chart.config.options.elements.center) {
                                // Get ctx from string
                                var ctx = chart.ctx;

                                // Get options from the center object in options
                                var centerConfig = chart.config.options.elements.center;
                                var fontStyle = centerConfig.fontStyle || 'Arial';
                                var txt = centerConfig.text;
                                var color = centerConfig.color || '#000';
                                var maxFontSize = centerConfig.maxFontSize || 75;
                                var sidePadding = centerConfig.sidePadding || 20;
                                var sidePaddingCalculated = (sidePadding / 100) * (chart.innerRadius * 2)
                                // Start with a base font of 30px
                                ctx.font = chart.config.options.sizeFontCenter + fontStyle;

                                // Get the width of the string and also the width of the element minus 10 to give it 5px side padding
                                var stringWidth = ctx.measureText(txt).width;
                                var elementWidth = (chart.innerRadius * 2) - sidePaddingCalculated;

                                // Find out how much the font can grow in width.
                                var widthRatio = elementWidth / stringWidth;
                                var newFontSize = Math.floor(30 * widthRatio);
                                var elementHeight = (chart.innerRadius * 2);

                                // Pick a new font size so it will not be larger than the height of label.
                                var fontSizeToUse = Math.min(newFontSize, elementHeight, maxFontSize);
                                var minFontSize = centerConfig.minFontSize;
                                var lineHeight = centerConfig.lineHeight || 25;
                                var wrapText = false;

                                if (minFontSize === undefined) {
                                    minFontSize = 20;
                                }

                                if (minFontSize && fontSizeToUse < minFontSize) {
                                    fontSizeToUse = minFontSize;
                                    wrapText = true;
                                }

                                // Set font settings to draw it correctly.
                                ctx.textAlign = 'center';
                                ctx.textBaseline = 'middle';
                                var centerX = ((chart.chartArea.left + chart.chartArea.right) / 2);
                                var centerY = ((chart.chartArea.top + chart.chartArea.bottom) / 2);
                                ctx.font = fontSizeToUse + "px " + fontStyle;
                                ctx.fillStyle = color;

                                if (!wrapText) {
                                    ctx.fillText(txt, centerX, centerY);
                                    return;
                                }

                                var words = txt.split(' ');
                                var line = '';
                                var lines = [];

                                // Break words up into multiple lines if necessary
                                for (var n = 0; n < words.length; n++) {
                                    var testLine = line + words[n] + ' ';
                                    var metrics = ctx.measureText(testLine);
                                    var testWidth = metrics.width;
                                    if (testWidth > elementWidth && n > 0) {
                                        lines.push(line);
                                        line = words[n] + ' ';
                                    } else {
                                        line = testLine;
                                    }
                                }

                                // Move the center up depending on line height and number of lines
                                centerY -= (lines.length / 2) * lineHeight;

                                for (var n = 0; n < lines.length; n++) {
                                    ctx.fillText(lines[n], centerX, centerY);
                                    centerY += lineHeight;
                                }
                                //Draw text in center
                                ctx.fillText(line, centerX, centerY);
                            }
                        }
                    }
                    const config = {
                        type: chartType,
                        data: {
                            labels: labels,
                            datasets: datasets,
                        },
                        options: {
							sizeFontCenter: chart.getAttribute('data-chartjs-small')=='true' ? "20px " : "30px ",
                            borderWidth: 0,
                            spacing: 0,
                            offset: 0,
                            weight: 0,
                            cutout: '83%',
                            animation: {
                                animateRotate : false,
                                animateScale: false
                            },
                            maintainAspectRatio: false,
                            layout: {
                                padding: 20
                            },
                            plugins: {
                                legend: {
                                    onHover: that.handleHover,
                                    onLeave: that.handleLeave,
                                    labels: {
                                        boxWidth: chart.getAttribute('data-chartjs-small')=='true' ? 11 : 20,
                                        boxHeight: chart.getAttribute('data-chartjs-small')=='true' ? 11 : 20,
                                        pointStyle: 'rect',
                                        textAlign: 'left',
                                        color: '#000000',
                                        font: {
                                            family: '"Open Sans", Arial, sans-serif',
                                            size: chart.getAttribute('data-chartjs-small')=='true' ? 11 : 13
                                        },
                                        generateLabels: (chart) => {
                                            const datasets = chart.data.datasets;
                                            return datasets[0].dataDisplay.map((data, i) => ({
                                                text: chart.data.labels[i] + ' : ' + data,
                                                fillStyle: datasets[0].backgroundColor[i],
                                                lineWidth: 0,
                                                index: i
                                            }))
                                        }
                                    },
                                    padding: 5,
                                    position: chart.getAttribute('data-chartjs-small')=='true' ? 'bottom' : 'right',
                                    align: 'start',
                                    maxWidth: 400,
                                    fullSize: true,
                                    display:true,
                                },
                                tooltip: {
                                    enabled: false
                                }
                            },

                            elements: {
                                center: {
                                    text: chart.getAttribute('data-chartjs-total'),
                                    color: '#000000', // Default is #000000
                                    fontStyle: '"Open Sans", Arial, sans-serif', // Default is Arial
                                    sidePadding: 20, // Default is 20 (as a percentage)
                                    minFontSize: chart.getAttribute('data-chartjs-small')=='true' ? 14 : 18, // Default is 20 (in px), set to false and text will not wrap.
                                    lineHeight: chart.getAttribute('data-chartjs-small')=='true' ? 18 : 23 // Default is 25 (in px), used for when text wraps
                                }
                            }
                        },
                        plugins: [
                            addTextCenterDoughnutPlugin
                        ],
                    };
                    new Chart(ctx, config);
                }
                else {
                    switch (chartKind) {
                        case "1":
                            const chartKind1 = new Chart(ctx, {
                                type: chartType,
                                data: {
                                    labels: labels,
                                    datasets: datasets
                                },
                                options: {
                                    responsive: true,
                                    maintainAspectRatio: false,
                                    layout: {
                                        padding: 0
                                    },
                                    indexAxis: 'y',
                                    scales: {
                                        y: {
                                            grid: {
                                                display: false,
                                                borderWidth: 0
                                            },
                                            drawBorder: false,
                                            stacked: true,
                                            maxTicksLimit: 100,
                                            ticks: {
                                                display: false
                                            }
                                        },
                                        x: {
                                            grid: {
                                                display: false,
                                                borderWidth: 0
                                            },
                                            drawBorder: false,
                                            beginAtZero: true,
                                            max: 100,
                                            maxTicksLimit: 100,
                                            ticks: {
                                                display: false
                                            }
                                        }
                                    },
                                    barThickness: 26,
                                    plugins: {
                                        tooltip: {
                                            enabled: false
                                        },
                                        legend: {
                                            labels: {
                                                usePointStyle: true,
                                                pointStyle: 'circle',
                                                boxWidth: 9,
                                                font: {
                                                    family: 'Open Sans',
                                                    size: 14
                                                }
                                            }
                                        }
                                    },
                                    events: [],
                                    animation: {
                                        duration: 1000,
                                        onComplete: function (animation) {
                                            const chartInstance = animation.chart,
                                                ctx = chartInstance.ctx,
                                                labels = this.data.labels,
                                                f = new FontFace('Open Sans', 'url(' + fontPath + ')'),
                                                that = this;

                                            f.load().then(function () {
                                                that.data.datasets.forEach(function (dataset, i) {
                                                    const meta = chartInstance.getDatasetMeta(i);

                                                    meta.data.forEach(function (bar, index) {
                                                        const xOffset = 10,
                                                            yOffset = bar.y,
                                                            canvasWidth = ctx.canvas.clientWidth;

                                                        // Bar label
                                                        ctx.textAlign = "left";
                                                        ctx.font = "normal normal normal 14px 'Open Sans'";
                                                        ctx.fillText(labels[index], xOffset, yOffset - 25);

                                                        // Inside bar weight label
                                                        if (rawshippedDataArray[index] > 0 && percentageArray[index] < 100) {
                                                            ctx.fillStyle = "#ffffff";
                                                            ctx.font = "normal normal normal 14px 'Open Sans'";
                                                            ctx.fillText(rawshippedDataArray[index] + " T", xOffset, yOffset);
                                                        }

                                                        // Percentage label
                                                        ctx.textAlign = "right";
                                                        ctx.fillStyle = "#000000";
                                                        ctx.font = "normal normal normal 12px 'Open Sans'";
                                                        ctx.fillText("Sur " + Math.trunc(rawTotalDataArray[index]) + " T contractualisées", canvasWidth, yOffset + 25);

                                                        // Reste à ... label
                                                        ctx.textAlign = "right";
                                                        ctx.fillStyle = "#000000";
                                                        ctx.font = "bold normal normal 12px 'Open Sans'";

                                                        if (rawAffectedDataArray[index] < rawTotalDataArray[index]) {
                                                            if (rawshippedDataArray[index] < rawTotalDataArray[index]) {
                                                                ctx.fillText("Reste à livrer = " + Math.trunc(rawTotalDataArray[index] - rawshippedDataArray[index]) + " T", canvasWidth, yOffset + 40);
                                                            } else {
                                                                ctx.fillText("Reste à vendre = " + Math.trunc(rawshippedDataArray[index] - rawTotalDataArray[index]) + " T", canvasWidth, yOffset + 40);
                                                            }
                                                        }

                                                        // Total weight label
                                                        ctx.textAlign = "right";
                                                        ctx.fillStyle = "#000000";
                                                        ctx.font = "normal normal normal 14px 'Open Sans'";
                                                        ctx.fillText(percentageArray[index] + "%", canvasWidth, yOffset - 25);
                                                    });
                                                });
                                            });
                                        }
                                    },
                                    onResize: function (chart, size) {

                                    }
                                }
                            });
                            break;
                        case "2":
                            const myChartType2 = new Chart(ctx, {
                                type: chartType,
                                data: {
                                    labels: labels,
                                    datasets: datasets
                                },
                                options: {
                                    responsive: true,
                                    maintainAspectRatio: false,
                                    layout: {
                                        padding: 0
                                    },
                                    indexAxis: 'y',
                                    scales: {
                                        y: {
                                            grid: {
                                                display: false,
                                                borderWidth: 0
                                            },
                                            drawBorder: false,
                                            stacked: true,
                                            maxTicksLimit: 100,
                                            ticks: {
                                                display: false
                                            }
                                        },
                                        x: {
                                            grid: {
                                                display: false,
                                                borderWidth: 0
                                            },
                                            drawBorder: false,
                                            beginAtZero: true,
                                            max: 100,
                                            maxTicksLimit: 100,
                                            ticks: {
                                                display: false
                                            }
                                        }
                                    },
                                    barThickness: 26,
                                    plugins: {
                                        tooltip: {
                                            enabled: false
                                        },
                                        legend: {
                                            labels: {
                                                usePointStyle: true,
                                                pointStyle: 'circle',
                                                boxWidth: 9,
                                                font: {
                                                    family: 'Open Sans',
                                                    size: 14
                                                }
                                            }
                                        }
                                    },
                                    events: [],
                                    animation: {
                                        duration: 1000,
                                        onComplete: function (animation) {
                                            const chartInstance = animation.chart,
                                                ctx = chartInstance.ctx,
                                                labels = this.data.labels,
                                                f = new FontFace('Open Sans', 'url(' + fontPath + ')'),
                                                that = this;

                                            f.load().then(function () {
                                                that.data.datasets.forEach(function (dataset, i) {
                                                    const meta = chartInstance.getDatasetMeta(i);

                                                    meta.data.forEach(function (bar, index) {
                                                        const xOffset = 10,
                                                            yOffset = bar.y,
                                                            canvasWidth = ctx.canvas.clientWidth;

                                                        // Bar label
                                                        ctx.textAlign = "left";
                                                        ctx.fillStyle = "#000000";
                                                        ctx.font = "normal normal normal 14px 'Open Sans'";
                                                        ctx.fillText(labels[index], xOffset, yOffset - 25);

                                                        // Inside bar weight label
                                                        ctx.fillStyle = "#ffffff";
                                                        ctx.font = "normal normal normal 14px 'Open Sans'";
                                                        if (rawshippedDataArray[index] > 0) {
                                                            ctx.fillText(rawshippedDataArray[index] + " T", xOffset, yOffset);
                                                        } else {
                                                            ctx.fillText("", xOffset, yOffset);
                                                        }

                                                        // Percentage label
                                                        ctx.textAlign = "right";
                                                        ctx.fillStyle = "#000000";
                                                        ctx.font = "normal normal normal 12px 'Open Sans'";
                                                        ctx.fillText("total " + rawTotalDataArray[index] + " T contractualisées", canvasWidth, yOffset + 25);

                                                        // Total weight label
                                                        ctx.textAlign = "left";
                                                        ctx.fillStyle = "#FFFFFF";
                                                        ctx.font = "normal normal normal 14px 'Open Sans'";
                                                        ctx.fillText(rawPriceArray[index] + " T", bar.x + 11, yOffset);
                                                    });
                                                });
                                            });
                                        }
                                    },
                                    onResize: function (chart, size) {

                                    }
                                }
                            });
                            break;
                        case "3":
                            const myChartType3 = new Chart(ctx, {
                                type: chartType,
                                data: {
                                    labels: labels,
                                    datasets: datasets
                                },
                                options: {
                                    responsive: true,
                                    maintainAspectRatio: false,
                                    ticks: {
                                        font: {
                                            family: 'Open Sans',
                                            size: 13
                                        }
                                    },
                                    plugins: {
                                        legend: {
                                            labels: {
                                                usePointStyle: true,
                                                pointStyle: 'circle',
                                                boxWidth: 9,
                                                font: {
                                                    family: 'Open Sans',
                                                    size: 14
                                                }
                                            }
                                        }
                                    },
                                    indexAxis: 'y',
                                    barThickness: 13,
                                    scales: {
                                        y: {
                                            grid: {
                                                display: false,
                                            },
                                            drawBorder: false,
                                            afterFit: function (scaleInstance) {
                                                if (window.innerWidth >= 768) {
                                                    scaleInstance.width = 216; // sets the width to 216px
                                                }
                                            }
                                        },
                                        x: {
                                            grid: {
                                                display: false,
                                            },
                                            drawBorder: false,
                                            ticks: {
                                                callback: function (value, index, values) {
                                                    return value + " " + getXTickValue;
                                                }
                                            }
                                        }
                                    }
                                }
                            });
                            break;
                        case "4":
                            new Chart(ctx, {
                                type: chartType,
                                data: {
                                    labels: labels,
                                    datasets: datasets
                                },
                                options: {
                                    responsive: true,
                                    maintainAspectRatio: true,
                                    borderWidth: 0,
                                    spacing: 0,
                                    offset: 0,
                                    weight: 0,
                                    layout: {
                                        padding: 20
                                    },
                                    indexAxis: 'y',
                                    scales: {
                                        y: {
                                            beginAtZero: true,
                                        },
                                    },
                                    datasets: {
                                        bar: {
                                            barPercentage: '0.6',
                                            categoryPercentage: '0.5'
                                        }
                                    },
                                    plugins: {
                                        legend: {
                                            position: 'top',
                                            labels: {
                                                boxWidth: 20,
                                                boxHeight: 20,
                                                pointStyle: 'rect',
                                            }
                                        },
                                    }
                                }
                            });
                            break;
                    }
                }
            });
        },

        cleanupEvents: function () {
            Chart.helpers.each(Chart.instances, function(instance){
                instance.destroy();
            });
        },

        handleHover: function (evt, item, legend) {
            legend.chart.data.datasets[0].backgroundColor.forEach((color, index, colors) => {
                colors[index] = index === item.index || color.length === 9 ? color : color + '4D';
            });
            legend.chart.update();
        },

        handleLeave: function (evt, item, legend) {
            legend.chart.data.datasets[0].backgroundColor.forEach((color, index, colors) => {
                colors[index] = color.length === 9 ? color.slice(0, -2) : color;
            });
            legend.chart.update();
        }
    }
}
