/**
 * @namespace
 */
var swiper = {};
(function() {

    /**
     * @memberOf swiper
     */
    function  initOnce(elt) {

        var _elt = $(elt),
            params =  $.extend({},_elt.data())
        _swiperSlidesperview = (params['swiperSlidesperview']) ? params['swiperSlidesperview'] : 1;

        var config = (_elt.data('swiper-config')) ? _elt.data('swiper-config') : "";

        var _swiper =  new Swiper(_elt, {
            spaceBetween: (params['swiperSpacebetween']) ? params['swiperSpacebetween'] : 0,
            slidesPerView:"auto",
            initialSlide : (parseInt(params['swiperInitialslide'])) ? parseInt(params['swiperInitialslide']) : 0,
            pagination: {
                el: (params['swiperPagination']) ? $("#" + params['swiperPagination']) : _elt.next().find('.swiper-control-pagination'),
                clickable: true,
                type: (params['swiperPaginationType']) ? params['swiperPaginationType'] : 'fraction'
            },
            freeMode: (params['swiperFreemode']) ? true : false,
            centeredSlides: (params['swiperCenteredslides']) ? true : false,
            direction: (params['swiperDirection']) ? params['swiperDirection'] : "horizontal",
            autoplay:{
                delay: (params['swiperAutoplaydelay']) ? params['swiperAutoplaydelay'] : 100000000000,
            },
            loop:(params['swiperLoop']) ? true : false,
            navigation: {
                nextEl: (_elt.find('.swiper-button-next').length) ? _elt.find('.swiper-button-next') : _elt.siblings('.swiper-button-next'),
                prevEl: (_elt.find('.swiper-button-prev').length) ? _elt.find('.swiper-button-prev') : _elt.siblings('.swiper-button-prev')
            },
            effect:(params['swiperEffect']) ? params['swiperEffect'] : "slider", // "slide", "fade", "cube", "coverflow" or "flip"
            on: {
                init: function () {
                    _elt.removeClass('invisible');
                },
            },
            breakpoints: {
                // when window width is <= 0px
                0 : {
                    slidesPerView : (params['swiperSlidesperviewXs']) ? params['swiperSlidesperviewXs'] : _swiperSlidesperview
                },
                // when window width is <= 768
                768: {
                    slidesPerView : (params['swiperSlidesperviewSm']) ? params['swiperSlidesperviewSm'] : _swiperSlidesperview
                },
                // when window width is <= 992
                992: {
                    slidesPerView: (params['swiperSlidesperviewMd']) ? params['swiperSlidesperviewMd'] : _swiperSlidesperview
                },
                // when window width is <= 1440
                1440: {
                    slidesPerView: _swiperSlidesperview
                },
                // when window width is <= 100000000
                100000: {
                    slidesPerView: _swiperSlidesperview
                }
            },
            paginationBulletRender: function (index, className) {

            },
            paginationCustomRender: function (swiper, current, total) {

            }
        });

        return _swiper;

    }
    swiper.initOnce=initOnce;

    /**
     * @memberOf swiper
     */
    function  initAll() {
        var i = 0;
        $(document).find('*[data-swiper]').once("data-swiper").each(function (key, value) {
            $(this).addClass('s'+key);
            swiper.initOnce($('.s'+key));
            i++;
        });
    }
    swiper.initAll=initAll;
})();


$(document).ready( function(){
    swiper.initAll();
});

$(document).ajaxComplete(
    function() {
        swiper.initAll();
    }
);
