var StoreLocatorConstructor = (function() {

    function StoreLocatorConstructor(eltObj,gmapObj) {

        var _self = this;
        this.gmap = {
            id : eltObj.data("gmap-id"),
            map : gmapObj,
            request : eltObj.data("gmap-request")
        };
        this.request();
        this.events();
    };


    StoreLocatorConstructor.prototype.events = function(template,data){
        var _self = this;
        $(document).on('click', '[data-storelocator-action="choice"]', function(){
            var marker = _self.gmap.map.markers[$(this).data("gmapIdx")];
            $("[data-storelocator-target='choice']").html(handlebarsDom.compileFromDomID("tpl_storelocator_choice")(marker));
        });

    };

    StoreLocatorConstructor.prototype.request = function(){

        var _self = this;
        $.ajax({
            method: "GET",
            url:_self.gmap.request,
            data:"",
            dataType: "json",
            success: function(json){

                $.each(json.results, function (i, obj) {
                    _self.gmap.map.pushMarker(obj,i);
                });
                _self.gmap.map.buildMarker();
                //_self.gmap.map.buildMarkerList();
                _self.gmap.map.centerGmap(new google.maps.LatLng((json.coords) ? Number(json.coords.lat) : 48.857277, (json.coords) ? Number(json.coords.lng) : 2.352275));
            },
            error: function(data,err,txt){}
        });

    }

    return StoreLocatorConstructor;

})();


var StoreLocatorController = (function(GoogleMap) {

    var GoogleMap = GoogleMap;

    function build() {

        var objGMap = GoogleMap.constructor();
        var selectElement = GoogleMap.getSelectElement();
        objGMap.geoLoc();
        objGMap.autoComplete();
        objGMap.geoCoder();
        objGMap.listMarkersEvent();
        new StoreLocatorConstructor(selectElement,objGMap);

    };

    function launch(){

        $('*[data-storelocator-init]').once('storelocator').each(function (key, value) {
            GoogleMap.display($(this),build);
        });

        $(document).on("click","*[data-storelocator-action='show:map']",function (key, value) {
            GoogleMap.display($(this),build);
        });

    };

    return {
        launch: launch
    };

})(GoogleMapController);

$(document).ready( function(){
    StoreLocatorController.launch();
});
