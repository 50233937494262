var selectPicker = {};
(function() {

    /**
     * @memberOf selectPicker
     */
    function initOnce(elt){
        var $elt = elt;

        $elt.selectpicker();
    }
    selectPicker.initOnce=initOnce;

    /**
     * @memberOf selectPicker
     */
    function initAll(){
        $(document).find('*[data-bootstrap-selectpicker="true"]').once('selectpicker').each(function (key, value) {
            selectPicker.initOnce($(this));
        });
    }
    selectPicker.initAll=initAll;

    return selectPicker;

})();



$(document).ready( function(){
    selectPicker.initAll();
});

$(document).ajaxComplete(
    function() {
        selectPicker.initAll();
    }
);