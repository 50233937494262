function dtLayer() {
    return {
        setup: function () {
            this.DOM = {
                layerContainer: null, // Associated to the DOM layer content elem when created
                oldContentLocation: null, // Used to restore content when removing
            };

            this.state = {
                setup: false, // layer is setted up
                ready: false, // is content ready to be shown ?
                toggled: false, // is layer shown ?
            };

            this.updateLayerContentHeight = this.updateLayerContentHeight.bind(this);
        },

        cleanup: function () {
            this.destroyLayer();
        },

        createLayer: function () {
            this.DOM.layerContainer = document.createElement('div');
            this.DOM.layerContainer.classList.add('dt-c-Layer');
            this.DOM.layerContainer.setAttribute('data-mode', 'generic');

            // Wrapper where content is injected
            this.DOM.layerContent = document.createElement('div');
            this.DOM.layerContent.classList.add('dt-c-Layer-content');

            // Header with actions like close or label
            this.DOM.layerHeader = document.createElement('div');
            this.DOM.layerHeader.classList.add('dt-c-Layer-top', 'dt-u-hspace');

            this.DOM.layerHeaderLabel = document.createElement('div');
            this.DOM.layerHeaderLabel.classList.add('dt-c-Layer-top-label');
            this.DOM.layerHeader.appendChild(this.DOM.layerHeaderLabel);

            this.DOM.layerHeaderClose = document.createElement('button');
            this.DOM.layerHeaderClose.classList.add('dt-c-Layer-top-close');
            this.DOM.layerHeaderClose.innerText = 'Fermer le panneau';
            this.DOM.layerHeaderClose.addEventListener('click', this.hide.bind(this));
            this.DOM.layerHeader.appendChild(this.DOM.layerHeaderClose);

            // Inject nodes in DOM
            this.DOM.layerContainer.appendChild(this.DOM.layerHeader);
            this.DOM.layerContainer.appendChild(this.DOM.layerContent);

            this.element.appendChild(this.DOM.layerContainer);

            // Listen to screenChange
            document.addEventListener('dtviewresize', this.updateLayerContentHeight);

            this.state.setup = true;
        },

        destroyLayer: function () {
            if (this.DOM.layerContainer){
                // Restore injected content to original place
                if (this.DOM.oldContentLocation){
                    this.DOM.oldContentLocation.appendChild(this.DOM.layerContent.childNodes[0]);
                }

                // Remove layer from DOM
                this.DOM.layerContainer.remove();

                document.body.removeAttribute('data-layer-shown');

                this.state.setup = false;
                this.state.ready = false;
                this.state.toggled = false;
            }
        },


        prepareLayer: function (element, name, label) {
            if (!this.state.setup)
                this.createLayer();

            if (this.state.ready)
                return;

            // Save original content location to restore if needed
            this.DOM.oldContentLocation = element.parentElement;

            // Move element into layer
            this.DOM.layerContent.innerHTML = '';
            this.DOM.layerContent.appendChild(element);

            // Setup layer header label
            this.DOM.layerHeaderLabel.innerText = (!!label) ? label : '';
            this.DOM.layerHeader.setAttribute('data-background', !!label);

            // Secure layer content height depending on layer header
            this.updateLayerContentHeight();

            // Setup layer name
            this.DOM.layerContainer.setAttribute('data-mode', !!name ? name : 'generic');
            this.DOM.layerContainer.setAttribute('data-shown', 'false');
            document.body.setAttribute('data-layer-shown', 'false');

            this.state.ready = true;
        },

        updateLayerContentHeight: function () {
            this.DOM.layerContent.setAttribute('style', 'height : ' + (window.innerHeight - this.DOM.layerHeader.offsetHeight) + 'px;');
        },

        show: function (element, name, label) {
            if (!element || this.state.toggled)
                return;

            // Prepare layer content if necessary
            this.prepareLayer(element, name, label);

            this.DOM.layerContainer.setAttribute('data-shown', 'true');
            document.body.setAttribute('data-layer-shown', 'true');

            this.state.toggled = true;
        },

        hide: function () {
            if (!this.state.toggled)
                return;

            this.DOM.layerContainer.setAttribute('data-shown', 'false');
            document.body.setAttribute('data-layer-shown', 'false');

            this.state.toggled = false;

            document.dispatchEvent(new Event('layerHidden'));
        }
    };
}