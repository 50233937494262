function dtSearchResultsFiltersToolbar() {
    return {
        setup: function () {
            this.DOM = {
                togglers: qsa('[data-filters-layer="toggler"]', this.element)
            };

            this.state = {
                toggled: false,
            };

            this.toggleFilterLayer = this.toggleFilterLayer.bind(this);

            this.layer = dtComponents.getComponent(document.body, 'dt-layer');
            this.setupEvents();
        },

        cleanup: function () {
            this.cleanupEvents();
        },

        setupEvents: function () {
            var that = this;

            this.DOM.togglers.forEach( function (toggler) {
                toggler.addEventListener('click', this.toggleFilterLayer);
            }, this);
        },

        cleanupEvents: function () {
            this.DOM.togglers.forEach( function (toggler) {
                toggler.removeEventListener('click', this.toggleFilterLayer);
            }, this);
        },

        toggleFilterLayer: function (event) {
            event.preventDefault();
            this.layer.destroyLayer();

            this.DOM.content = qs('[data-filters-layer="content"]', this.element);
            var label = qs('[data-filters-layer="toggler"]', this.element);

            this.layer.show(this.DOM.content, 'filter', label.textContent);
        }
    }
}