/**
 * @namespace
 */
var scrolltofixed = {};
(function() {

    scrolltofixed.enabled = false;

    function init(elt){

        if(bpoint.isBreakpointSMup()) {
            var _elt = elt,
                params = $.extend({}, _elt.data());

            _elt.scrollToFixed({
                removeOffsets: true,
                marginTop: (params["scrolltofixedMargintop"]) ? params["scrolltofixedMargintop"] : 0,
                zIndex: (params["scrolltofixedIndex"]) ? params["scrolltofixedIndex"] : 1040,
                limit: function () {
                    if (params['scrolltofixedLimit'] && params['scrolltofixedLimitBottom']) {
                        var _bottom = params['scrolltofixedLimitBottom'];
                        var limit = $(params['scrolltofixedLimit']).offset().top - _elt.outerHeight(true) - _bottom;
                        return limit;
                    } else if (params['scrolltofixedLimit']) {
                        var limit = $(params['scrolltofixedLimit']).offset().top - _elt.outerHeight(true) - 10;
                        return limit;
                    } else {
                        var limit = 0;
                    }

                },
                postFixed: function () {

                },
                preFixed: function () {

                }
            });
        }

    };
    scrolltofixed.init = init;

    function initAll(){
        $(document).find('*[data-scrolltofixed]').once("data-scrolltofixed").each(function (key, value) {
            scrolltofixed.init($(value));
        });
    };

    scrolltofixed.initAll = initAll;

})();

$(document).ready( function(){
    scrolltofixed.initAll();
});

$(document).ajaxComplete(
    function() {
        scrolltofixed.initAll();
    }
);
