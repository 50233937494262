function dtDatepicker() {
    return {
        setup: function () {
            this.DOM = {
                togglers: qsa('[data-datepicker]', this.element),
            };

            this.initEvents();
        },

        cleanup: function () {
            this.cleanupEvents();
            this.element.dispatchEvent(new Event('afterCleanup'));
        },

        initEvents: function () {
            this.DOM.togglers.forEach(function (toggler) {
                const language = (toggler.getAttribute('data-datepicker-regional')) ? toggler.getAttribute('data-datepicker-regional') : "en",
                    dateFormat = (toggler.getAttribute('data-datepicker-dateformat')) ? toggler.getAttribute('data-datepicker-dateformat') : "yy-mm-dd",
                    firstDay = (toggler.getAttribute('data-datepicker-firstday')) ? parseInt(toggler.getAttribute('data-datepicker-firstday')) : 0;

                $(toggler).datepicker({
                    firstDay: firstDay,
                    dateFormat: dateFormat,
                    language: language
                });
            }, this);

            this.element.dispatchEvent(new Event('afterSetup'));
        },

        cleanupEvents: function () {
            this.DOM.togglers.forEach(function (toggler) {
                $(toggler).datepicker("destroy");
            }, this);
        }
    }
}
